import { Command } from '../Command';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { notify } from '_common/components/ToastSystem';

export class EditCaptionCommand extends Command {
  private options: Editor.Edition.CaptionCommandOptions;

  constructor(context: Editor.Edition.Context, options: Editor.Edition.CaptionCommandOptions) {
    super(context);
    this.options = options;
  }

  async handleExec() {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid Context');
    }

    const captionDefenition = this.context.DataManager.captions.caption(this.options.label);

    const captionData: Partial<Editor.Data.Structure.CaptionData> = {};

    let shouldUpdate = false;
    if (this.options.numberingType && this.options.numberingType !== captionDefenition.numbering) {
      captionData.nf = this.options.numberingType;
      shouldUpdate = true;
    }

    if (this.options.chapterNumbering) {
      shouldUpdate = true;
      captionData.c = this.options.chapterNumbering.chapterType;

      let newSeparator = this.options.chapterNumbering.separator;
      if (newSeparator) {
        if (captionDefenition.chapter) {
          if (newSeparator !== captionDefenition.separator) {
            if (this.options.force) {
              captionData.s = newSeparator;
            } else {
              const closestField = NodeUtils.closestOfTypeByPath(
                this.actionContext.baseData,
                this.actionContext.range.start.p,
                ['f'],
              );
              if (closestField) {
                const previousSibling = NodeUtils.getPreviousSibling(
                  this.actionContext.baseData,
                  closestField.path,
                );

                if (
                  previousSibling &&
                  NodeUtils.isTextData(previousSibling.data) &&
                  previousSibling.data.content !== newSeparator
                ) {
                  this.actionContext.baseModel.set(
                    [...previousSibling.path, 'content'],
                    newSeparator,
                    {
                      source: 'LOCAL_RENDER',
                    },
                  );
                } else {
                  const textData = new NodeDataBuilder('text').setContent(newSeparator).build();
                  if (textData) {
                    this.context.contentManipulator.insertContent(
                      this.actionContext,
                      closestField.path,
                      textData,
                    );
                  }
                }
                notify({
                  type: 'warning',
                  title: 'CAPTIONS_SEPARATOR_NOT_UPDATED',
                  message: 'CAPTIONS_SEPARATOR_NOT_UPDATED_MESSAGE',
                  messageValues: { captionLabel: captionDefenition.label },
                });
              }
            }
          } else {
            captionData.s = captionDefenition.separator;
          }
        } else {
          captionData.s = this.options.chapterNumbering.separator;
        }
      }
    }

    if (shouldUpdate) {
      this.context.DataManager.captions.updateCaption(this.options.label, captionData);
    }

    await this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
