import { Logger } from '_common/services';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';

export default class ExtensionHelper {
  static EXTENSION_ID = 'ldcfpijeeomligejkphbjcoielolkaae';

  private static async chromeSendMessage(action: string) {
    try {
      if (chrome.runtime) {
        const response = await chrome.runtime.sendMessage(ExtensionHelper.EXTENSION_ID, {
          action,
        });
        return response;
      }
    } catch (error) {
      Logger.error(error);
    }
  }

  private static async firefoxSendMessage(action: string, callback: (response?: any) => void) {
    // TODO: this is not working at the moment
    // WARN: window.sendMessage is a function injected directely from extension in runtime
    //@ts-expect-error
    if (window.sendMessage) {
      //@ts-expect-error
      await window.sendMessage({ action }, callback);
    } else {
      callback();
    }
  }

  private static checkExtension(platform: Platform, notification: boolean = true) {
    return new Promise(async (resolve, reject) => {
      const responseHandler = (response: any) => {
        if (response) {
          resolve(response);
        } else {
          if (notification) {
            ReduxInterface.openAndUpdateModal({
              modal: 'ClipboardInstallExtensionModal',
              data: {
                chrome: platform.browser.chrome,
                firefox: platform.browser.firefox,
              },
            });
          }
          reject(new Error('Extension not found!'));
        }
      };

      try {
        if (platform) {
          if (platform.browser.chrome) {
            const response = await ExtensionHelper.chromeSendMessage('info');
            responseHandler(response);
          } else if (platform.browser.firefox) {
            // check firefox extension
            await ExtensionHelper.firefoxSendMessage('info', responseHandler);
          } else {
            // other browsers
            throw new Error('Browser not supported!');
          }
        } else {
          throw new Error('No platform info!');
        }
      } catch (error) {
        Logger.captureException(error);
        if (notification) {
          ReduxInterface.openModal('ClipboardInfoModal');
        }
        reject(error);
      }
    });
  }

  static triggerPasteEvent(platform: Platform) {
    return ExtensionHelper.checkExtension(platform)
      .then(() => {
        window.dispatchEvent(new Event('trigger_paste'));
      })
      .catch(() => {});
  }

  static readClipboardData(platform: Platform): Promise<any> {
    return ExtensionHelper.checkExtension(platform).then(() => {
      return new Promise(async (resolve, reject) => {
        if (platform) {
          if (platform.browser.chrome) {
            const result = await ExtensionHelper.chromeSendMessage('readclipboard');
            resolve(result);
          } else if (platform.browser.firefox) {
            // check firefox extension
            ExtensionHelper.firefoxSendMessage('readclipboard', resolve);
          } else {
            // other browsers
            reject(new Error('Browser not supported!'));
          }
        } else {
          reject(new Error('No platform info!'));
        }
      });
    });
  }

  static getFontFamilyList(platform: Platform): Promise<any> {
    return ExtensionHelper.checkExtension(platform, false).then(() => {
      return new Promise(async (resolve, reject) => {
        if (platform) {
          if (platform.browser.chrome) {
            const result = await ExtensionHelper.chromeSendMessage('fontlist');
            resolve(result);
          } else if (platform.browser.firefox) {
            // check firefox extension
            ExtensionHelper.firefoxSendMessage('fontlist', resolve);
          } else {
            // other browsers
            reject(new Error('Browser not supported!'));
          }
        } else {
          reject(new Error('No platform info!'));
        }
      });
    });
  }
}
