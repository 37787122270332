import { Structure, Section } from '../../models';
import BaseController from '../BaseController';
import { ModelIndexer } from '../Models/ModelIndexer';

type SectionsControllerArgType = Pick<Editor.Data.State, 'transport' | 'models' | 'context'>;

const WEBLAYOUT_SECTION_SIZE = {
  portrait: {
    pt: 516, // points
    px: 688, // pixels
  },
  landscape: {
    pt: 709.5, // points
    px: 946, // pixels
  },
} as const;

export class SectionsController extends BaseController {
  private structure?: Structure;
  private sectionsList?: ModelIndexer<'SECTION'>;

  constructor(Data: SectionsControllerArgType) {
    super(Data);
    this.handleSectionListLoad = this.handleSectionListLoad.bind(this);
    this.handleSectionListInsert = this.handleSectionListInsert.bind(this);
    this.handleSectionListRemove = this.handleSectionListRemove.bind(this);
    this.handleSectionListError = this.handleSectionListError.bind(this);
  }

  start(documentId: string): void {
    this.structure = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.STRUCTURE,
      `DS${documentId}`,
    );
    this.sectionsList = this.Data.models?.getIndexer(
      this.Data.models.TYPE_NAME.SECTION,
    ) as ModelIndexer<'SECTION'>;

    this.sectionsList?.on('LOADED', this.handleSectionListLoad);
    this.sectionsList?.on('INSERTED', this.handleSectionListInsert);
    this.sectionsList?.on('REMOVED', this.handleSectionListRemove);
    this.sectionsList?.on('ERROR', this.handleSectionListError);
    this.sectionsList?.start({
      parent_id: documentId,
      st: 'A',
    });
  }

  get WEBLAYOUT_SECTION_SIZE() {
    return WEBLAYOUT_SECTION_SIZE;
  }

  private bindToPreviousSections() {
    const results = this.sectionsList?.results || [];
    for (let index = 0; index < results.length; index++) {
      const section = results[index];
      if (section.previousSection) {
        const previousSection = this.getSectionById(section.previousSection);
        if (previousSection) {
          section.bindToPrevious(previousSection);
        }
      }
    }
  }

  private handleSectionListLoad(data: Section[]) {
    this.bindToPreviousSections();
  }

  private handleSectionListInsert(data: Section[]) {
    this.bindToPreviousSections();
  }

  private handleSectionListRemove(data: Section[]) {
    this.bindToPreviousSections();
  }

  private handleSectionListError(error: Error) {}

  get list() {
    return this.sectionsList;
  }

  get numberOfSections() {
    return this.sectionsList?.results.length;
  }

  getSectionById(sectionId: string) {
    return this.Data.models?.get(this.Data.models.TYPE_NAME.SECTION, sectionId);
  }

  stop(): void {}

  destroy(): void {}

  getSectionOfBlock(blockId: string): string | undefined {
    const blockProps = this.structure?.blockProperties;
    if (blockId != null && blockProps?.[blockId]) {
      return blockProps[blockId].sct || '';
    }
    return undefined;
  }

  async updateSectionProperties(
    sectionId: string,
    properties: Pick<Editor.Data.Sections.Properties, 'p_o' | 'mar' | 'sz' | 't'>,
    options?: Editor.Data.Sections.UpdateOptions,
  ) {
    if (sectionId) {
      const sectionData = this.getSectionById(sectionId)?.get() as Editor.Data.Sections.Data;

      const sectionProperties = { ...sectionData.p };

      //data validations
      sectionProperties.mar = { ...sectionProperties.mar, ...properties.mar };
      sectionProperties.sz = { ...sectionProperties.sz, ...properties.sz };

      sectionProperties.p_o = properties.p_o;
      sectionProperties.t = properties.t;

      if (sectionProperties.sz.h && sectionProperties.sz.w) {
        if (sectionProperties.sz.h > sectionProperties.sz.w) {
          sectionProperties.p_o = 'P';
        } else {
          sectionProperties.p_o = 'L';
        }
      }

      if (
        (sectionProperties.sz?.h !== sectionData.p.sz?.h ||
          sectionProperties.sz?.w !== sectionData.p.sz?.w) &&
        (sectionProperties.t === 'c' || sectionProperties.t === 'nc')
      ) {
        sectionProperties.t = 'np';
      }

      return new Promise((resolve, reject) => {
        this.Data.transport.dispatchEvent(
          'SECTION:UPDATE',
          {
            section: sectionId,
            properties: sectionProperties,
            options,
          },
          (response) => {
            if (response.success) {
              resolve(response.payload);
            } else {
              reject(response.error);
            }
          },
        );
      });
    } else {
      throw new Error('Invalid arguments!');
    }
  }

  getSectionAttributes(sectionId: string): Editor.Data.Sections.Properties | undefined {
    const section = this.getSectionById(sectionId);

    return section?.properties;
  }

  getPageWidthForBlockId(blockId: string, layoutType: Editor.Visualizer.LayoutType = 'PAGE') {
    let pageWidth: number = this.WEBLAYOUT_SECTION_SIZE.portrait.pt;

    const sectionId = this.getSectionOfBlock(blockId);
    if (sectionId) {
      const sectionProps = this.getSectionAttributes(sectionId);

      if (layoutType === 'PAGE' && sectionProps?.sz?.w) {
        pageWidth = sectionProps.sz.w - (sectionProps?.mar?.l || 0) - (sectionProps?.mar?.r || 0);
      } else if (layoutType === 'WEB') {
        if (sectionProps?.p_o === 'L') {
          pageWidth = this.WEBLAYOUT_SECTION_SIZE.landscape.pt;
        } else {
          pageWidth = this.WEBLAYOUT_SECTION_SIZE.portrait.pt;
        }
      }
    }

    return pageWidth;
  }
}
