import { EditorService } from '_common/services';
import { CitationData, CitationsLibrary, DocumentCitations, Template } from '../../models';
import BaseController from '../BaseController';

type ReferenceStyleData = {
  name: string;
  id: string;
  package_id: string;
};

export class CitationsController extends BaseController {
  private _library?: CitationsLibrary;
  private _documentCitations?: DocumentCitations;
  private _template?: Template;
  private _stylesList: ReferenceStyleData[] = [];

  async start(documentId: string) {
    this._library = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.CITATIONS_LIBRARY,
      `CL${documentId}`,
    );
    this._documentCitations = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.DOCUMENT_CITATIONS,
      `CIT${documentId}`,
    );
    this._template = this.Data.models?.get(this.Data?.models.TYPE_NAME.TEMPLATE, documentId);
    this._template?.on('LOADED', (data: any) => {
      this.loadReferenceStyle();
    });
    this._library?.on('LOADED', (data: any) => {
      this.Data.events?.emit('LOAD_CITATIONS_LIBRARY', {
        citations: {},
        ...data,
        document: documentId,
      });

      this.loadCitations();
    });
    this._library?.on('UPDATED', (data: any) => {
      this.Data.events?.emit('LOAD_CITATIONS_LIBRARY', {
        citations: {},
        ...this.fixInserted(data),
        document: documentId,
      });

      this.loadCitations();
    });
    this._documentCitations?.on('LOADED', () => {
      this.loadCitations();
      this.loadReferenceStyle();
    });
    this._documentCitations?.on('UPDATED', () => {
      this.loadCitations();
      this.loadReferenceStyle();
    });
    await this.loadReferenceStyles();
  }

  stop(): void {}

  destroy(): void {
    if (this._library) {
      this._library.destroy();
    }
    if (this._documentCitations) {
      this._documentCitations.destroy();
    }
    if (this._template) {
      this._template.destroy();
    }
  }
  // ---------------------------------------------------------------------
  //                              Document
  // ---------------------------------------------------------------------
  documentCitations() {
    return this._documentCitations;
  }

  addCitationToDocument(citationId: string) {
    return this._documentCitations?.addCitationToDocument(citationId);
  }

  // ---------------------------------------------------------------------
  //                              Library
  // ---------------------------------------------------------------------
  get referenceStyle() {
    if (this._documentCitations?.referenceStyle) {
      return this._documentCitations?.referenceStyle;
    }
    if (this._template?.referenceStyle) {
      for (let index = 0; index < this._stylesList?.length; index++) {
        if (this._stylesList[index].package_id === this._template?.referenceStyle) {
          return this._stylesList[index].id;
        }
      }
    }
    return '_blank';
  }

  //! HOTFIX !!
  private fixInserted(data: any) {
    const documentOrder = this._documentCitations?.order || [];
    let payload = {
      ...data,
    };
    let order = Object.keys(data.citations);
    let citationId;
    for (let index = 0; index < order.length; index++) {
      citationId = order[index];
      payload.citations[citationId] = {
        ...this.citation(citationId),
        inserted: documentOrder.includes(citationId),
      };
    }
    return payload;
  }

  private async loadReferenceStyles() {
    const data = await new EditorService().listReferenceStyles();
    // @ts-expect-error EditorService api is still not yet documented
    this._stylesList = data.data;
    const styleList = this._stylesList?.map((referenceStyle: any) => {
      return { label: referenceStyle.name, value: referenceStyle.id };
    });

    this.Data.events?.emit('LOAD_DOCUMENT_REFERENCE_STYLES', styleList);
    this.loadReferenceStyle();
  }

  private loadCitations() {
    const order = this._documentCitations?.order || [];
    const payload: any = {
      order,
      citations: {},
      type: this._documentCitations?.orderType,
    };
    let citationId;
    for (let index = 0; index < order.length; index++) {
      citationId = order[index];
      payload.citations[citationId] = {
        ...this.citation(citationId),
        inserted: true,
      };
    }
    this.Data.events?.emit('LOAD_DOCUMENT_CITATIONS', JSON.parse(JSON.stringify(payload)));
  }

  private loadReferenceStyle() {
    this.Data.events?.emit('LOAD_DOCUMENT_REFERENCE_STYLE', this.referenceStyle);
  }

  library() {
    return this._library;
  }

  getCitationFromLibrary(citationId: string) {
    return this.library()?.citation(citationId);
  }

  getAllCitationsFromLibrary() {
    return this.library()?.getCitations();
  }

  citation(citationId: string) {
    return {
      id: citationId,
      ...(this._library?.citation(citationId) || {}),
      locations: this._documentCitations?.get(['loc', citationId]) || {},
      serial: (this._documentCitations?.order || []).indexOf(citationId) + 1,
    };
  }

  async addCitationsToLibrary(citations: CitationData[], source?: string) {
    if (this._library) {
      return this._library.addCitations(citations, source);
    }
  }

  async updateCitationInLibrary(citation: CitationData) {
    if (this._library) {
      return this._library.updateCitation(citation);
    }
  }

  async removeCitationFromLibrary(citationId: string) {
    if (this._library) {
      return this._library.removeCitation(citationId);
    }
  }

  async defineCitationPriority(citationId: string, priority: string) {
    if (this._library) {
      return this._library.defineCitationPriority(citationId, priority);
    }
  }

  removeCitationLocation(citationId: string, location: string) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'CITATION:REMOVE:LOCATION',
        {
          citation: citationId,
          location: location,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  removeCitation(citationId: string) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'CITATION:DELETE',
        {
          citation: citationId,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getCitationsChronologicalOrder() {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'CITATION:CHRONO:ORDER',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  updateCitationsNumbering() {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'CITATION:NUMBERING:UPDATE',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  updateReferenceSection() {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'REFERENCE:SECTION:REFRESH',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  setReferenceStyle(refStyleId: string) {
    return this._documentCitations?.setReferenceStyle(refStyleId);
  }
}
