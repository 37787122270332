import { uniq, cloneDeep } from 'lodash-es';
import DocumentStyle, { DEFAULT_STYLE_OBJECT } from './DocumentStyle';
import { DocumentStyleData, Structure, Template } from '../../models';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { Logger } from '_common/services';

export type MergedStylesData = {
  [index: string]: DocumentStyle;
};

type TemplateStylesPropType = {
  [index: string]: DocumentStyleData;
};

export type DocumentStylesPropType = {
  [index: string]: DocumentStyleData;
};

type StylesListsPropType = {
  [index: string]: string[];
};

export default class DocumentStylesManager extends BaseTypedEmitter<{
  LOADED: (data: MergedStylesData) => void;
  UPDATED: (data: DocumentStyle) => void;
  STYLES_ADD: (data: DocumentStyle) => void;
  STYLES_REMOVE: (id: DocumentStyleData['id']) => void;
}> {
  private template?: Template;
  private structure?: Structure;
  private templateStyles: TemplateStylesPropType | null = null;
  private documentStyles: DocumentStylesPropType | null = null;
  private stylesLists: StylesListsPropType = {};
  private stylesModels: MergedStylesData = {};

  constructor(templateObject?: Template, structureObject?: Structure) {
    super();
    if (templateObject) {
      this.bindToTemplate(templateObject);
    }
    if (structureObject) {
      this.bindToStructure(structureObject);
    }
  }

  get loaded() {
    return this.structure?.loaded && this.template?.loaded;
  }

  private joinStyles() {
    this.joinStylesData();
  }

  private joinStylesData() {
    try {
      //! TESTING APPROACH
      this.stylesLists = {};
      const newStyles = uniq([
        ...Object.keys(DEFAULT_STYLE_OBJECT),
        ...Object.keys(this.templateStyles || {}),
        ...Object.keys(this.documentStyles || {}),
      ]);
      const stylesIds = uniq([...newStyles, ...Object.keys(this.stylesModels)]);
      //
      const roots = [];
      const styleChildren: { [index: string]: string[] } = {};
      //
      let styleId;
      for (let index = 0; index < stylesIds.length; index++) {
        styleId = stylesIds[index];
        if (!newStyles.includes(styleId) && this.stylesModels[styleId]) {
          delete this.stylesModels[styleId];
          this.emit('STYLES_REMOVE', styleId);
        } else {
          if (!this.stylesModels[styleId]) {
            this.stylesModels[styleId] = new DocumentStyle(
              styleId,
              this.templateStyles?.[styleId] || null,
              this.documentStyles?.[styleId] || null,
            );
            this.emit('STYLES_ADD', this.stylesModels[styleId]);
          } else {
            this.stylesModels[styleId].loadStyleData(
              this.templateStyles?.[styleId] || null,
              this.documentStyles?.[styleId] || null,
            );
          }
          if (this.stylesModels[styleId].extends) {
            let parentStyleId = this.stylesModels[styleId].extends as string;
            if (!styleChildren[parentStyleId]) {
              styleChildren[parentStyleId] = [];
            }
            styleChildren[parentStyleId].push(styleId);
          } else {
            roots.push(styleId);
          }
          let styleNumberingId = this.stylesModels[styleId].styleDefinedListId;
          if (styleNumberingId) {
            if (!this.stylesLists[styleNumberingId]) {
              this.stylesLists[styleNumberingId] = [];
            }
            this.stylesLists[styleNumberingId].push(styleId);
          }
        }
      }
      //! EXTEND STYLE
      const handleStyleExtension = (parentStyleId: string) => {
        if (!styleChildren[parentStyleId]) {
          return;
        }
        this.stylesModels[parentStyleId].children = [];
        while (styleChildren[parentStyleId].length > 0) {
          const childrenStyleId = styleChildren[parentStyleId].shift();
          if (childrenStyleId) {
            this.stylesModels[parentStyleId].children.push(this.stylesModels[childrenStyleId]);
            this.stylesModels[childrenStyleId].loadNewParentProperties(
              this.stylesModels[parentStyleId].extendedP,
              false,
            );
            handleStyleExtension(childrenStyleId);
          }
        }
      };
      for (let i = 0; i < roots.length; i++) {
        handleStyleExtension(roots[i]);
      }
    } catch (e) {
      Logger.captureException(e);
    }

    this.emit('LOADED', this.stylesModels);
  }

  bindToTemplate(templateObject?: Template) {
    if (templateObject) {
      this.template = templateObject;
      this.template.on('LOADED', (data: any) => {
        this.loadTemplateStyles(this.template?.styles);
      });

      if (this.template?.loaded) {
        this.loadTemplateStyles(this.template?.styles);
      }
    }
    return this;
  }

  bindToStructure(structureObject?: Structure) {
    if (structureObject) {
      this.structure = structureObject;

      this.structure.on('LOADED', () => {
        this.loadDocumentStyles(this.structure?.styles);
      });
      this.structure.on('UPDATED', (data, ops) => {
        let element: any;
        for (
          let index = 0;
          ops && index < (ops as Array<Realtime.Core.RealtimeOps>).length;
          index++
        ) {
          element = ops[index];
          if (element.p[0] === 'st') {
            if (element.p.length === 2) {
              //* ADD / REMOVE
              if (element.oi && element.od) {
                //* Kind of update
                this.appendDocumentStyle(
                  element.p[1],
                  this.structure?.get(element.p) as unknown as DocumentStyleData,
                );
              } else if (element.oi) {
                this.appendDocumentStyle(
                  element.p[1],
                  this.structure?.get(element.p) as unknown as DocumentStyleData,
                );
              } else if (element.od) {
                // TODO: validate this!!
                this.removeDocumentStyle(element.p[1]);
              }
            } else if (element.p.length > 2) {
              //* UPDATED STYLES
              this.appendDocumentStyle(
                element.p[1],
                this.structure?.get(element.p) as unknown as DocumentStyleData,
              );
            } else {
              this.loadDocumentStyles(this.structure?.styles);
            }
          }
        }
      });

      if (this.structure.loaded) {
        this.loadDocumentStyles(this.structure.styles);
      }
    }
    return this;
  }

  start() {
    /* if (this.structure?.loaded) {
      this.loadDocumentStyles(this.structure?.styles);
    } */

    this.joinStyles();
  }

  style(styleId: string) {
    return this.stylesModels[styleId];
  }

  styles() {
    return this.stylesModels;
  }

  stylesData() {
    return Object.keys(this.stylesModels).reduce((data: any, styleId) => {
      data[styleId] = this.stylesModels[styleId].data;
      return data;
    }, {});
  }

  loadTemplateStyles(templateStyles: any) {
    //loop
    this.templateStyles = templateStyles;
    this.joinStyles();
  }

  loadDocumentStyles(documentStyles: any) {
    this.documentStyles = cloneDeep(documentStyles);
    this.joinStyles();
  }

  appendDocumentStyle(styleId: string, styleData: DocumentStyleData) {
    const styleExists = !!this.stylesModels[styleId];
    if (!this.documentStyles) {
      this.documentStyles = {};
    }
    this.documentStyles[styleId] = cloneDeep(styleData);
    this.joinStyles();

    if (styleExists) {
      this.emit('UPDATED', this.stylesModels[styleId]);
    }
  }

  removeDocumentStyle(styleId: string) {
    if (this.documentStyles?.[styleId]) {
      delete this.documentStyles[styleId];
    }
    this.joinStyles();

    if (this.templateStyles?.[styleId]) {
      this.emit('UPDATED', this.stylesModels[styleId]); //template only true?
    }
  }

  addNewStyle(styleId: string, spec: any) {
    if (this.stylesModels[styleId]) {
      throw new Error(`New style ID already exists: ${styleId}`);
    }
    const stylesData = this.structure?.get(['st']);
    const _spec = DocumentStyle.parseBack({}, spec);
    if (!stylesData) {
      // ADD STYLES OBJECT
      return this.structure?.set(['st'], {
        [styleId]: _spec,
      });
    }
    return this.structure?.set(['st', styleId], _spec, { source: 'LOCAL_RENDER' });
  }

  getStylesOnOutline(): Record<DocumentStyleData['id'], number> {
    return Object.keys(this.stylesModels).reduce((data: any, styleId) => {
      if (
        this.stylesModels[styleId].outlineValue !== undefined &&
        this.stylesModels[styleId].outlineValue !== null
      ) {
        data[styleId] = this.stylesModels[styleId].outlineValue;
      }
      return data;
    }, {});
  }

  getDescendentStylesOfStyle(styleId: string) {
    const queue: DocumentStyle[] = [];
    const result: string[] = [];
    let style;
    if (!this.stylesModels[styleId]) {
      return result;
    }
    queue.push(this.stylesModels[styleId]);
    while (queue.length) {
      style = queue.shift();
      if (style?.children?.length) {
        const childrenIds = style.children.map((value: DocumentStyle) => value.id);
        queue.push(...style.children);
        result.push(...childrenIds);
      }
    }
    return result;
  }

  getDescendentStylesWithList(styleId: string, listId: string) {
    const queue = [styleId];
    const result = [];
    let style;
    let auxStyleId;
    while (queue.length && (auxStyleId = queue.shift())) {
      //is the first condition redundant? shift returns undefined on empty arrays
      style = this.stylesModels[auxStyleId];
      if (style) {
        if (!style.extendedP?.lst || (style.extendedP.lst && style.extendedP.lst.lId === listId)) {
          //could style be undefined, if styleId is not a valid style id?
          result.push(style.id);
        }
        if (style.extendedP?.lst && style.extendedP.lst.lId == null) {
          continue;
        }
        if (style.children.length) {
          const childrenIds = style.children.map((value: DocumentStyle) => value.id);
          queue.push(...childrenIds);
        }
      }
    }
    return result;
  }

  getStylesAndDescendentStyles(styleIds: string[]) {
    const queue = [...styleIds];
    const result = [...styleIds];
    let style;
    while (queue.length) {
      style = queue.shift();
      if (style && this.stylesModels[style].children.length) {
        const childrenIds = this.stylesModels[style].children.map(
          (value: DocumentStyle) => value.id,
        );
        queue.push(...childrenIds);
        result.push(...childrenIds);
      }
    }
    return result;
  }

  hasStyleParentStyleWithList(styleId: string, listId: string) {
    const queue = [styleId];
    let style;
    let auxStyleId;
    while (queue.length && (auxStyleId = queue.shift())) {
      style = this.stylesModels[auxStyleId];
      if (style.p && style.p.lst) {
        if (style.p.lst.lId == null) {
          return null;
        }
        if (style.p.lst.lId === listId) {
          return style.id;
        }
      }
      if (this.stylesModels[style.id].e) {
        queue.push(this.stylesModels[style.id].e as string);
      }
    }
    return null;
  }

  hasStyleParentOfType(styleId: string, parents: string[]) {
    const queue = [styleId];
    let style;
    while (queue.length && (style = queue.shift())) {
      if (parents.includes(style)) {
        return style;
      }
      if (this.stylesModels[style].e) {
        queue.push(this.stylesModels[style].e as string);
      }
    }
    return null;
  }

  getStylesForList(listId: string) {
    const mlTypes = this.stylesLists[listId] || [];
    const mlWithDescendents = mlTypes.reduce((acc: string[], value: string) => {
      const descendents = this.getDescendentStylesWithList(value, listId);
      acc.push(...descendents);
      return acc;
    }, []);
    return uniq(mlWithDescendents); //set?
  }

  hasListsWithStyles() {
    return Object.keys(this.stylesLists).length;
  }

  getListsWithStyles() {
    return Object.keys(this.stylesLists);
  }

  hasStyleAList(styleId: string) {
    const style = this.stylesModels[styleId];
    if (style && style.extendedP && style.extendedP.lst && style.extendedP.lst.lId != null) {
      return style.extendedP.lst.lId;
    }
    return null;
  }

  hasStyleIndentation(styleId: string) {
    const style = this.stylesModels[styleId];
    if (style && style?.p?.ind) {
      return style.p.ind;
    }
    return null;
  }

  isStyleFromTemplate(styleId: string) {
    return this.stylesModels[styleId]?.hasTemplateDefinition;
  }

  hasStyleDocumentDefinition(styleId: string) {
    return this.stylesModels[styleId]?.hasDocumentDefinition;
  }

  getStyleKeepLines(styleId: string) {
    if (this.stylesModels[styleId]?.extendedP?.kl != null) {
      return this.stylesModels[styleId]?.extendedP?.kl;
    }
  }

  getStyleKeepWithNext(styleId: string) {
    if (this.stylesModels[styleId]?.extendedP?.kn != null) {
      return this.stylesModels[styleId]?.extendedP?.kn;
    }
  }

  getStylePageBreakBefore(styleId: string) {
    if (this.stylesModels[styleId]?.extendedP?.pbb != null) {
      return this.stylesModels[styleId]?.extendedP?.pbb;
    }
  }

  getBaseStyleId(styleId: string) {
    let style = this.stylesModels[styleId];

    if (style) {
      if (style.b === true) {
        return style.id;
      }

      while (style.e) {
        style = this.stylesModels[style.e];

        if (style.b === true) {
          return style.id;
        }
      }
    }
    return null;
  }

  getStyleByName(name: string) {
    const keys = Object.keys(this.stylesModels);

    let i;
    for (i = 0; i < keys.length; i++) {
      if (this.stylesModels[keys[i]].n === name) {
        return this.stylesModels[keys[i]];
      }
    }

    return null;
  }

  doesStyleNameExists(name: string) {
    const keys = Object.keys(this.stylesModels);

    let i;
    for (i = 0; i < keys.length; i++) {
      if (this.stylesModels[keys[i]].n === name) {
        return keys[i];
      }
    }
    return false;
  }

  private getNameAndIdForStyle(baseStyle: DocumentStyle) {
    if (baseStyle) {
      let name = baseStyle.n || '';
      const id = DocumentStyle.generateStyleId();

      if (baseStyle.b) {
        name = name.charAt(0).toUpperCase() + name.slice(1);
      }

      do {
        if (name.includes('(') && name.includes(')')) {
          const index = parseInt(name.substring(name.indexOf('(') + 1, name.indexOf(')')), 10);

          name = `${name.substring(0, name.indexOf('('))}(${index + 1})`;
        } else {
          name = `${name} (1)`;
        }
      } while (this.doesStyleNameExists(name));

      return { name, id };
    }

    return {};
  }

  createNewStyleFromStyle(baseStyleId: string, styleStatus: any) {
    if (!this.stylesModels[baseStyleId]) {
      throw new Error('Not found style with id : ' + baseStyleId);
    }
    const { name, id } = this.getNameAndIdForStyle(this.stylesModels[baseStyleId]);

    const newStyle = {
      p: styleStatus.p,
      n: name,
      id,
      b: false,
      e: baseStyleId,
    };

    if (!id) {
      throw new Error('No id found for new style');
    }
    if (this.stylesModels[id]) {
      throw new Error(`New style Id already exists: ${id}`);
    }
    this.addNewStyle(id, newStyle);
    return id;
  }

  listStyleUpdated(listId: string) {
    const styles = this.getStylesForList(listId);

    for (let i = 0; i < styles.length; i++) {
      const styleModel = this.stylesModels[styles[i]];
      styleModel.emit('UPDATED', styleModel);
    }
  }

  destroy(): void {
    super.destroy();
  }
}
