import { useDispatch, useEllipsisCheck } from '_common/hooks';
import { Link, Tooltip } from 'dodoc-design-system';
import { addToSelectionQueue } from '../TableSlice';
import styles from './Cell.module.scss';

type ObjectLocationCellProps = {
  object: Objekt;
};

const ObjectLocationCell = ({ object }: ObjectLocationCellProps) => {
  const { ref, hasEllipsis } = useEllipsisCheck<HTMLDivElement>();
  const dispatch = useDispatch();
  const isSharedLocation = object?.location?.includes('Shared');

  const handleClick = () => {
    if (!object) return;

    let identity: Table.Identity = 'storage';

    if (isSharedLocation) {
      if (object.location === 'Shared') {
        identity = 'shared';
      } else {
        identity = object.parent ? 'storage' : 'shared';
      }
    }

    dispatch(addToSelectionQueue({ identity, objectId: object.id }));
  };

  const getLocationUrl = () => {
    if (isSharedLocation) {
      if (object.location === 'Shared') return '/shared';
      return object.parent ? `/storage/folder/${object.parent}` : '/shared';
    }

    if (object.parent) return `/storage/folder/${object.parent}`;
    if (object.space) return `/storage/space/${object.space}`;

    return null;
  };

  const locationUrl = getLocationUrl();

  if (!locationUrl) return null;

  return (
    <div ref={ref} className={styles.location}>
      <Tooltip
        disabled={!hasEllipsis}
        content={object.location}
        testId={`${object.id}-location-tooltip`}
      >
        <Link
          size="small"
          onClick={handleClick}
          testId={`${object.id}-location-link`}
          href={locationUrl}
        >
          {object.location}
        </Link>
      </Tooltip>
    </div>
  );
};

export default ObjectLocationCell;
