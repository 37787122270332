import { SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { ELEMENTS } from 'Editor/services/consts';
import { InsertColumnOperation, InsertRowOperation } from '../../Operations/TableOperations';
import { TableUtils } from '../../Utils/TableUtils';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { ClipboardManager } from 'Editor/services/Clipboard';
import { Command } from '../Command';
import { ErrorElementNotEditable } from '../../Errors';

type TablePasteContext = {
  parsedTable: {
    rows: Editor.Data.Node.Data[];
    totalRows: number;
    numColumnsByRow: number[];
  };
  table: {
    id: string;
    rows: Editor.Data.Node.Data[];
    selectedCells: Editor.Elements.Table.CellInfo[];
    selectedRowsIndexs: number[];
    totalRows: number;
    totalColumns: number;
    path: Editor.Selection.Path;
    selectedCellsIndexs: number[][];
  };
  updateBaseData: boolean;
  pageWidth: number | undefined;
};

export class PasteCommand extends Command {
  private parsedData: Editor.Clipboard.ParsedJsonData;
  private listsMapper: Editor.Clipboard.AfterPasteMatchIdList = {};
  private pasteOptionsStyle: Editor.Clipboard.PasteOptions;
  private isTableToTable: boolean = false;

  constructor(
    context: Editor.Edition.Context,
    parsedData: Editor.Clipboard.ParsedJsonData,
    pasteOptionsStyle: Editor.Clipboard.PasteOptions,
  ) {
    super(context);

    this.parsedData = parsedData;
    this.pasteOptionsStyle = pasteOptionsStyle;
  }

  private async handlePasteFromTableOnCell(
    ctx: Editor.Edition.ActionContext,
    tablePasteContext: TablePasteContext,
  ) {
    const table = tablePasteContext.table;
    const parsedTable = tablePasteContext.parsedTable;
    const startCellIndex = table.selectedCellsIndexs[0][0];
    const startRowIndex = table.selectedRowsIndexs[0];

    if (parsedTable.totalRows > table.totalRows - startRowIndex) {
      const numberRowsToAdd = parsedTable.totalRows - (table.totalRows - startRowIndex);

      // add missing rows to the baseNode table
      for (let i = 0; i < numberRowsToAdd; i++) {
        const operation = new InsertRowOperation(
          ctx.baseModel,
          table.id,
          [],
          [table.totalRows - 1],
          {
            before: false,
          },
        );

        operation.apply();
        tablePasteContext.updateBaseData = true;
      }
    }

    if (parsedTable.numColumnsByRow[0] > table.totalColumns - startCellIndex) {
      const numberColumnsToAdd =
        parsedTable.numColumnsByRow[0] - (table.totalColumns - startCellIndex);

      // add missing columns to the baseNode table
      for (let i = 0; i < numberColumnsToAdd; i++) {
        const operation = new InsertColumnOperation(
          ctx.baseModel,
          table.id,
          [],
          tablePasteContext.pageWidth,
          [table.totalColumns - 1],
          {
            before: false,
          },
        );

        operation.apply();
        tablePasteContext.updateBaseData = true;
      }
    }

    if (tablePasteContext.updateBaseData && this.context.DataManager) {
      ctx.refreshBaseData();

      if (!ctx.baseData) {
        return false;
      }

      const closestTable = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, ['tbl']);

      if (!closestTable) {
        return this;
      }

      await this.handleTableToTable(ctx, closestTable);
    } else {
      // iterate parsedTable rows
      for (let rowIndex = 0; rowIndex < parsedTable.totalRows; rowIndex++) {
        // iterate parsedTable cells
        for (let cellIndex = 0; cellIndex < parsedTable.numColumnsByRow[rowIndex]; cellIndex++) {
          const cell =
            table.rows[rowIndex + startRowIndex].childNodes?.[cellIndex + startCellIndex];

          if (!cell) {
            continue;
          }

          const cellPath: Editor.Selection.Path = [
            ...table.path,
            'childNodes',
            0,
            'childNodes',
            rowIndex + startRowIndex,
            'childNodes',
            cellIndex + startCellIndex,
          ];

          if (cell.childNodes?.length) {
            const startPath: Editor.Selection.Path = [
              ...cellPath,
              'childNodes',
              0,
              'childNodes',
              0,
            ];

            const lastCellIndex = cell.childNodes.length - 1;
            const lastCellChild = cell.childNodes[lastCellIndex];
            const childNodesLenght = lastCellChild.childNodes?.length || 0;

            const endPath: Editor.Selection.Path = [
              ...cellPath,
              'childNodes',
              lastCellIndex,
              'childNodes',
              childNodesLenght,
            ];

            ctx.range.updateRangePositions(
              {
                b: ctx.range.start.b,
                p: startPath,
              },
              {
                b: ctx.range.start.b,
                p: endPath,
              },
            );

            this.handleNonCollapsedSelection(ctx);
          } else {
            ctx.range.updateRangePositions({
              b: ctx.range.start.b,
              p: [...cellPath, 'childNodes', 0],
            });
          }

          const parsedCell = parsedTable.rows[rowIndex].childNodes?.[cellIndex];

          if (parsedCell && parsedCell.childNodes) {
            await this.handleCollapsedSelection(ctx, parsedCell.childNodes);
          }
        }
      }
    }
  }

  private async handlePasteFromTableOnMultipleCells(
    ctx: Editor.Edition.ActionContext,
    tablePasteContext: TablePasteContext,
  ) {
    const table = tablePasteContext.table;
    const parsedTable = tablePasteContext.parsedTable;

    for (
      let r = table.selectedRowsIndexs[0], pr = 0;
      r <= table.selectedRowsIndexs[table.selectedRowsIndexs.length - 1] &&
      pr < parsedTable.totalRows;
      r++, pr++
    ) {
      if (table.rows[r] && parsedTable.rows[pr]) {
        const cells = table.rows[r].childNodes;
        const parsedCells = parsedTable.rows[pr].childNodes;
        for (
          let c = table.selectedCellsIndexs[pr][0], pc = 0;
          c <= table.selectedCellsIndexs[pr][1] && pc <= parsedTable.numColumnsByRow[pr];
          c++, pc++
        ) {
          const cell = cells?.[c];
          const parsedCell = parsedCells?.[pc];

          if (cell?.properties?.d === false) {
            continue;
          }

          if (cell && parsedCell && cell.childNodes) {
            const cellPath: Editor.Selection.Path = [
              ...table.path,
              'childNodes',
              0,
              'childNodes',
              r,
              'childNodes',
              c,
            ];

            const startPath: Editor.Selection.Path = [
              ...cellPath,
              'childNodes',
              0,
              'childNodes',
              0,
            ];

            const lastCellIndex = cell.childNodes.length - 1;
            const lastCellChild = cell.childNodes[lastCellIndex];
            const childNodesLenght = lastCellChild.childNodes?.length || 0;

            const endPath: Editor.Selection.Path = [
              ...cellPath,
              'childNodes',
              lastCellIndex,
              'childNodes',
              childNodesLenght,
            ];

            ctx.range.updateRangePositions(
              {
                b: ctx.range.start.b,
                p: startPath,
              },
              {
                b: ctx.range.start.b,
                p: endPath,
              },
            );

            this.handleNonCollapsedSelection(ctx);

            if (parsedCell.childNodes) {
              this.isTableToTable = true;
              await this.handleCollapsedSelection(ctx, parsedCell.childNodes);
            }
          }
        }
      }
    }
  }

  private getTotalCellsByRow(rows: Editor.Data.Node.Data[]) {
    let cellsNumber: number[] = [];

    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];

      if (row.childNodes) {
        let numColumns = 0;
        for (let c = 0; c < row.childNodes.length; c++) {
          numColumns++;
        }

        cellsNumber.push(numColumns);
      }
    }
    return cellsNumber;
  }

  private getSelectedCellsIndexs(cells: Editor.Elements.Table.CellInfo[]) {
    let selectedCellsIndexs: number[][] = [];
    let rowIndex;
    let startCellIndex;
    let endCellIndex;

    for (let c = 0; c < cells.length; c++) {
      let cell = cells[c];

      if (rowIndex == null) {
        rowIndex = cell.sectionRowIndex;
      }

      if (cell.sectionRowIndex !== rowIndex) {
        if (startCellIndex != null && endCellIndex != null) {
          selectedCellsIndexs.push([startCellIndex, endCellIndex]);
        }
        rowIndex = cell.sectionRowIndex;
        startCellIndex = null;
        endCellIndex = null;
      }

      if (startCellIndex == null) {
        startCellIndex = cell.cellIndex;
      }

      endCellIndex = cell.cellIndex;

      if (c === cells.length - 1) {
        selectedCellsIndexs.push([startCellIndex, endCellIndex]);
      }
    }

    return selectedCellsIndexs;
  }

  protected async handleTableToTable(
    ctx: Editor.Edition.ActionContext,
    closestTable: Editor.Data.Node.DataPathInfo,
  ) {
    // TODO reduce function size
    // handle paste from table to table

    //this.context.clipboard?.allowOpenPasteOptions = false;

    if (!ctx.baseModel) {
      return this;
    }

    const parsedTableBody = this.parsedData.elementsData[0].childNodes?.[0];
    const parsedTableRows = parsedTableBody?.childNodes;

    // Get closestTable table total number of rows
    const tableRows = closestTable.data.childNodes?.[0].childNodes;

    if (parsedTableRows && tableRows) {
      const totalRows = tableRows.length;

      // Get parsedTable total number of columns
      const numColumns = this.getTotalCellsByRow(parsedTableRows);

      // Get parsedTable total number of rows
      const numRows = parsedTableRows.length;

      // Get baseNode table total number of columns
      let totalColumns;

      for (let r = 0; r < tableRows.length; r++) {
        const columns = tableRows[r].childNodes;
        if (columns && (!totalColumns || totalColumns < columns.length)) {
          totalColumns = columns.length;
        }
      }

      const range = ctx.range.serializeToDOMRange();

      if (range && numColumns && totalColumns) {
        const tableNode = EditorDOMUtils.closest(
          range.commonAncestorContainer,
          ELEMENTS.TableElement.TAG,
        );

        if (EditorDOMElements.isTableElement(tableNode)) {
          const selectedCells = tableNode.getSelectedCellsInfo();
          const tableId = tableNode.id;
          const selectedRowsIndexs = TableUtils.getRowsIndex(selectedCells);
          const selectedCellsIndexs = this.getSelectedCellsIndexs(selectedCells);

          const tablePasteContext: TablePasteContext = {
            parsedTable: {
              rows: parsedTableRows,
              totalRows: numRows,
              numColumnsByRow: numColumns,
            },
            table: {
              id: tableId,
              rows: tableRows,
              selectedCells: selectedCells,
              selectedRowsIndexs: selectedRowsIndexs,
              totalRows: totalRows,
              totalColumns: totalColumns,
              path: closestTable.path,
              selectedCellsIndexs: selectedCellsIndexs,
            },
            updateBaseData: false,
            pageWidth: this.context.DataManager?.sections.getPageWidthForBlockId(tableNode.id),
          };

          if (selectedCells.length < 2) {
            await this.handlePasteFromTableOnCell(ctx, tablePasteContext);
          } else {
            await this.handlePasteFromTableOnMultipleCells(ctx, tablePasteContext);
          }
        }
      }
    }
  }

  protected async handleNonTextElement(
    ctx: Editor.Edition.ActionContext,
    closestBlock: Editor.Data.Node.DataPathInfo,
  ) {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData
    ) {
      return false;
    }

    const paragraphData = NodeDataBuilder.buildParagraph();

    if (paragraphData && paragraphData.id) {
      this.context.contentManipulator.insertBlock(ctx, paragraphData, 'AFTER', {
        pathAfterInsert: 'START',
      });

      await this.handleTextElement(ctx, closestBlock, this.parsedData.elementsData);
    }
  }

  private async handleCitationsBeforePaste(
    ctx: Editor.Edition.ActionContext,
    data: Editor.Data.Node.Data,
  ) {
    if (!this.parsedData.citations || !this.context.DataManager) {
      return false;
    }

    let citations: Editor.Data.Node.Data[] = [];

    if (NodeUtils.isCitationData(data)) {
      citations.push(data);
    } else {
      let citationInfo = NodeUtils.querySelectorInData(data, ['citation']);
      for (let n = 0; n < citationInfo.length; n++) {
        citations.push(citationInfo[n].data);
      }
    }

    for (let i = 0; i < citations.length; i++) {
      const citation = citations[i];
      if (NodeUtils.isCitationData(citation)) {
        const oldId = citation.properties.element_reference;
        if (oldId) {
          const newCitation = this.parsedData.citations[oldId];
          if (!newCitation) {
            // Get the current existing citations just in case we copying citations that already exist
            const existingCitations: Editor.Data.Citations.CitationData[] =
              ReduxInterface.getCitationsObjects();

            if (citation.clipboardProps?.tempCitationInfo) {
              const info = JSON.parse(citation.clipboardProps.tempCitationInfo);
              let newId = existingCitations.find(
                ({ hash, doi }) =>
                  (hash != null && hash === info.hash) || (doi != null && doi === info.doi),
              )?.id;
              // Add the citation to the document library if it doesn't exist already
              if (!newId) {
                const source = info.source;

                newId = NodeUtils.generateUUID();
                delete info.hash;
                delete info.source;
                delete info.time;
                info.inserted = true;
                // WARN: carefull with  merge conflits
                // correct arguments, wrong function calling
                await this.context.DataManager.citations.addCitationsToLibrary(
                  [{ ...info, id: newId }],
                  source,
                );
              }

              if (newId) {
                this.parsedData.citations[oldId] = newId;
              }
            }
          }

          citation.properties.element_reference = this.parsedData.citations[oldId];
          await this.context.DataManager.citations.addCitationToDocument(
            this.parsedData.citations[oldId],
          );
        }

        delete citation.clipboardProps;
      }
    }
  }

  private handleFieldsBeforePaste(ctx: Editor.Edition.ActionContext, data: Editor.Data.Node.Data) {
    if (!this.parsedData.fields || !this.context.DataManager) {
      return false;
    }

    let fields: Editor.Data.Node.Data[] = [];

    if (NodeUtils.isFieldData(data)) {
      fields.push(data);
    } else {
      let fieldsInfo = NodeUtils.querySelectorInData(data, ['f']);
      for (let n = 0; n < fieldsInfo.length; n++) {
        fields.push(fieldsInfo[n].data);
      }
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (NodeUtils.isFieldData(field) && field.id) {
        const refId = field.clipboardProps?.tempCrossReferenceTarget;
        if (refId) {
          const refData = this.parsedData.fields[refId];
          if (refData.hasTarget && refData.targetRef) {
            field.properties.r = refData.targetRef;
          }
        }

        delete field.clipboardProps;
      }
    }
  }

  private async handleNotesBeforePaste(
    ctx: Editor.Edition.ActionContext,
    data: Editor.Data.Node.Data,
  ) {
    if (!this.parsedData.notes || !this.context.DataManager) {
      return false;
    }

    let notes: Editor.Data.Node.Data[] = [];

    if (NodeUtils.isNoteData(data)) {
      notes.push(data);
    } else {
      let notesInfo = NodeUtils.querySelectorInData(data, ['note']);
      for (let n = 0; n < notesInfo.length; n++) {
        notes.push(notesInfo[n].data);
      }
    }

    for (let i = 0; i < notes.length; i++) {
      const note = notes[i];
      if (NodeUtils.isNoteData(note)) {
        const tempId = note.clipboardProps?.tempId;
        if (tempId) {
          const newNote = this.parsedData.notes[tempId];
          if (newNote) {
            if (newNote.newId) {
              note.properties.element_reference = newNote.newId;
            } else {
              const newId = await this.context.DataManager.notes.addNote(
                null,
                newNote.type,
                newNote.text,
              );
              this.parsedData.notes[tempId].newId = newId;
              if (newId) {
                note.properties.element_reference = newId;
              }
            }
          }
        }

        delete note.clipboardProps;
      }
    }
  }

  private async handleParagraphBeforePaste(
    ctx: Editor.Edition.ActionContext,
    data: Editor.Data.Node.Data,
    baseDataId: string,
  ) {
    if (!this.context.DataManager) {
      return false;
    }

    const paragraph = data;
    if (NodeUtils.isParagraphData(paragraph)) {
      // handle field temp refs
      const tempCrossReferenceId = paragraph.clipboardProps?.tempCrossReferenceId;
      if (tempCrossReferenceId && this.parsedData.fields) {
        const references = tempCrossReferenceId.split(',');
        for (let i = 0; i < references.length; i++) {
          const refId = references[i];
          this.parsedData.fields[refId].targetRef = `${baseDataId}:${paragraph.id}`;
        }
      }

      // handle document style
      const styleName = paragraph.clipboardProps?.tempStyleId;
      if (styleName) {
        let documentStyle =
          this.context.DataManager.styles.documentStyles.getStyleByName(styleName);
        if (documentStyle) {
          paragraph.properties.s = documentStyle.id;
        } else {
          paragraph.properties.s = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
        }
      }

      // Update lists attributes

      if (paragraph.clipboardProps?.cp_list_id) {
        let listId: keyof Editor.Clipboard.AfterPasteMatchIdList =
          paragraph.clipboardProps?.cp_list_id;

        const listLevel = paragraph.clipboardProps.cp_list_level;
        const listStyleId = paragraph.clipboardProps.cp_list_style;

        if (
          paragraph.id &&
          listId &&
          listStyleId &&
          !this.context.DataManager.styles.listStyles.style(listStyleId)?.isMultiLevelList()
        ) {
          if (this.listsMapper?.[listId]) {
            this.context.DataManager.numbering.addBlocksToList(
              [paragraph.id],
              this.listsMapper[listId].id,
              listLevel || '0',
              this.listsMapper[listId].elements[this.listsMapper[listId].elements.length - 1],
            );
            this.listsMapper[listId].elements.push(paragraph.id);
          } else {
            let newListId = this.context.DataManager.numbering.createNewList(listStyleId);

            if (this.listsMapper) {
              this.listsMapper[listId] = {
                id: newListId,
                elements: [paragraph.id],
              };
              this.context.DataManager.numbering.addBlocksToList(
                [paragraph.id],
                newListId,
                listLevel || '0',
                undefined,
              );
            }
          }
        }
      }

      delete paragraph.clipboardProps;
    }
  }

  async handleTableBeforePaste(
    ctx: Editor.Edition.ActionContext,
    data: Editor.Data.Node.TableData,
  ) {
    if (!this.context.DataManager || !data.id) {
      return false;
    }

    // handle merged cells
    const mappedHeadIds: {
      [index: string]: string;
    } = {};

    const rows: Editor.Data.Node.Data[] = data.childNodes?.[0].childNodes || [];

    for (let r = 0; r < rows.length; r++) {
      const cells: Editor.Data.Node.Data[] = rows[r].childNodes || [];
      for (let c = 0; c < cells.length; c++) {
        const cell = cells[c];
        if (NodeUtils.isTableCellData(cell) && cell.id) {
          // cells with headIdRef
          const headIdRef = cell.clipboardProps?.['head-id-reference'];
          if (headIdRef) {
            mappedHeadIds[headIdRef] = cell.id;
          }

          const headId = cell.properties['head-id'];
          if (headId && mappedHeadIds[headId]) {
            cell.properties['head-id'] = mappedHeadIds[headId];
            cell.properties.d = false;
          }

          if (cell.clipboardProps) {
            delete cell.clipboardProps;
          }
        }
      }
    }

    // handle paragraphs
    const paragraphs: Editor.Data.Node.DataPathInfo[] = NodeUtils.querySelectorInData(data, ['p']);
    for (let i = 0; i < paragraphs.length; i++) {
      await this.handleParagraphBeforePaste(ctx, paragraphs[i].data, data.id);
    }
  }

  protected async handleTextElement(
    ctx: Editor.Edition.ActionContext,
    closestBlock: Editor.Data.Node.DataPathInfo,
    dataToInsert: Editor.Data.Node.Data[],
  ) {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData
    ) {
      return false;
    }

    for (let i = 0; i < dataToInsert.length; i++) {
      const data: Editor.Data.Node.Data = dataToInsert[i];
      const selectionOptions: Editor.Edition.InsertElementOptions = {};

      // WARN: order is important
      if (NodeUtils.isParagraphData(data) && data.id) {
        // handle paragraphs
        await this.handleParagraphBeforePaste(ctx, data, data.id);
      }

      if (NodeUtils.isTableData(data)) {
        //handle tables
        await this.handleTableBeforePaste(ctx, data);
      }

      // handle notes
      await this.handleNotesBeforePaste(ctx, data);
      // handle fields
      await this.handleFieldsBeforePaste(ctx, data);
      // handle citations
      await this.handleCitationsBeforePaste(ctx, data);

      if (this.pasteOptionsStyle === ClipboardManager.ORIGINAL_STYLES) {
        if (i === 0) {
          // split all format elements and insert directly in block element
          selectionOptions.forceInlineSplit = true;
        }
        // handle all format elements as wrap elements
        selectionOptions.forceTextAsWrap = true;
      } else if (this.pasteOptionsStyle === ClipboardManager.MATCH_DESTINATION && i !== 0) {
        // handle format elements as wrap elements
        selectionOptions.forceTextAsWrap = true;
      }

      if (NodeUtils.isBlockTypeData(data)) {
        if (i === 0 && this.canMergeContents(closestBlock.data, data)) {
          if (!this.isTableToTable) {
            this.context.contentManipulator.splitBlockContent(ctx, ctx.range.start.p, {
              setSelectionAfter: false,
              checkEmpty: true,
            });
          }

          const props = data.properties;
          if (props) {
            const keys = Object.keys(props);
            for (let a = 0; a < keys.length; a++) {
              const key = keys[a];
              if (
                key !== 'id' &&
                key !== 'parent_id' &&
                key !== 'element_type' &&
                key !== 'style'
              ) {
                let path = [...closestBlock.path, 'properties', key];
                //@ts-expect-error
                ctx.baseModel.set(path, props[key], { source: 'LOCAL_RENDER' });
              }
            }
          }

          const dataChildNodes = data.childNodes;
          if (dataChildNodes) {
            for (let c = 0; c < dataChildNodes.length; c++) {
              if (this.context.commandFactory) {
                // TODO: rework this to manipulators
                const command = this.context.commandFactory.getCommand('INSERT_INLINE');
                if (command) {
                  command.setExecOptions({
                    createPatch: false,
                  });
                  command.setActionContext(ctx);
                  await command.exec(dataChildNodes[c], selectionOptions);
                }
              }
            }
          }

          // if next child is inline, split content
          // call isInline data on index 1, if true
          // call split content
          if (NodeUtils.isInlineData(dataToInsert[i + 1])) {
            this.context.contentManipulator.splitBlockContent(ctx, ctx.range.start.p, {
              setSelectionAfter: true,
              checkEmpty: true,
            });
          }
        } else {
          this.context.contentManipulator.insertBlock(ctx, data, 'AFTER');
        }
      } else {
        if (this.context.commandFactory) {
          // TODO: rework this to manipulators
          const command = this.context.commandFactory.getCommand('INSERT_INLINE');
          if (command) {
            command.setExecOptions({
              createPatch: false,
            });
            command.setActionContext(ctx);
            await command.exec(data, selectionOptions);
          }
        }
      }
    }

    if (this.context.clipboard?.allowOpenPasteOptions) {
      this.insertPasteOptions(ctx);
    }
  }

  protected async handleCollapsedSelection(
    ctx: Editor.Edition.ActionContext,
    elementsData: Editor.Data.Node.Data[],
  ): Promise<boolean> {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    let baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    ctx.setModelAndData(baseModel, baseData);

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
      },
      this.context.DataManager,
    );

    const closestTable = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, ['tbl']);
    if (closestTable && NodeUtils.isTableData(elementsData[0])) {
      await this.handleTableToTable(ctx, closestTable);
    } else {
      const closestElement = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
        ...NodeUtils.BLOCK_TYPES,
        'tblc',
      ]);

      if (closestElement) {
        if (
          NodeUtils.isBlockTextData(closestElement.data) ||
          NodeUtils.isTableCellData(closestElement.data)
        ) {
          await this.handleTextElement(ctx, closestElement, elementsData);
        } else {
          await this.handleNonTextElement(ctx, closestElement);
        }
      }
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  canMergeContents(data1: Editor.Data.Node.Data, data2: Editor.Data.Node.Data) {
    if (
      NodeUtils.isParagraphData(data1) &&
      NodeUtils.isParagraphData(data2) &&
      this.context.DataManager &&
      data1.id &&
      data2.id
    ) {
      // both are lists
      if (
        (this.context.DataManager.numbering.isListElement(data1.id) ||
          data1.clipboardProps?.cp_list_id) &&
        (this.context.DataManager?.numbering.isListElement(data2.id) ||
          data2.clipboardProps?.cp_list_id)
      ) {
        return true;
      }

      // both are not lists
      if (
        !this.context.DataManager?.numbering.isListElement(data1.id) &&
        !data1.clipboardProps?.cp_list_id &&
        !this.context.DataManager?.numbering.isListElement(data2.id) &&
        !data2.clipboardProps?.cp_list_id
      ) {
        return true;
      }
    }

    return false;
  }

  private insertPasteOptions(ctx: Editor.Edition.ActionContext) {
    if (!this.context.clipboard) {
      return false;
    }
    const blockView = document.getElementById(ctx.range.start.b);
    const range = ctx.range.serializeToDOMRange();

    if (EditorDOMElements.isSupportedBlockElement(blockView)) {
      this.context.clipboard.openPasteOptions(blockView, range);
    }
  }

  async handleExec() {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (!this.actionContext) {
      throw new Error('Context is not defined');
    }

    // handle non collapsed selection
    if (!this.actionContext.range.collapsed) {
      this.handleNonCollapsedSelection(this.actionContext);
    }

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      await this.handleCollapsedSelection(this.actionContext, this.parsedData.elementsData);
    }

    await this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
