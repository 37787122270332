export default class ErrorElementToFocusNotFound extends Error {
  elementType: Editor.ElementToFocus['objectType'];
  elementId: Editor.ElementToFocus['objectId'];

  constructor(
    type: Editor.ElementToFocus['objectType'],
    elementId: Editor.ElementToFocus['objectId'],
  ) {
    super();
    this.name = 'ErrorElementToFocusNotFound';
    this.message = `Error element not found! Type: ${type}, Id: ${elementId}`;

    this.elementType = type;
    this.elementId = elementId;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorElementToFocusNotFound);
    }
  }
}
