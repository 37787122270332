import Cell from './Cell';
import CollaboratorsCell from './CollaboratorsCell';
import DateTimeCell from './DateTimeCell';
import ObjectDueDateCell from './ObjectDueDateCell';
import ObjectLocationCell from './ObjectLocationCell';
import ObjectNameCell from './ObjectNameCell';
import ObjectStatusCell from './ObjectStatusCell';
import OptionsCell from './OptionsCell';

export { objectIsOverdue } from './ObjectDueDateCell';

export default Object.assign(Cell, {
  Collaborators: CollaboratorsCell,
  DateTime: DateTimeCell,
  ObjectDueDate: ObjectDueDateCell,
  ObjectName: ObjectNameCell,
  ObjectStatus: ObjectStatusCell,
  Options: OptionsCell,
  Location: ObjectLocationCell,
});
