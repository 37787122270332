import { Structure, Template } from '../../models';
import BaseController from '../BaseController';

export class TemplatesController extends BaseController {
  _template?: Template;
  private structure?: Structure;

  constructor(Data: Editor.Data.State) {
    super(Data);

    this.handleStructureLoaded = this.handleStructureLoaded.bind(this);
    this.handleStructureTemplateUpdate = this.handleStructureTemplateUpdate.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
  }

  private handleEventSetDocumentTemplateFinished() {
    this.Data.events?.emit('SET_DOCUMENT_TEMPLATE_FINISHED');
  }

  async start(documentId: string): Promise<void> {
    this.registerTransportEvents({
      'SET:DOCUMENT:TEMPLATE:FINISHED': this.handleEventSetDocumentTemplateFinished.bind(this),
    });
    this._template = this.Data.models?.get(this.Data.models.TYPE_NAME.TEMPLATE, documentId);
    this.structure = this.Data.models?.get(this.Data.models.TYPE_NAME.STRUCTURE, `DS${documentId}`);

    this.structure?.on('LOADED', this.handleStructureLoaded);
    this.structure?.on('TEMPLATE_UPDATE', this.handleStructureTemplateUpdate);

    if (this.structure?.loaded) {
      // avoid re-rendering the editor at start
      await this.updateTemplate(false);
    }
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
  }

  private async handleStructureLoaded() {
    await this.updateTemplate();
  }

  private async handleStructureTemplateUpdate() {
    await this.updateTemplate();
  }

  private async updateTemplate(forceReRender: boolean = true) {
    await this._template?.fetch();
    const docId = this.Data.document?.getDocumentId();
    if (this.structure && docId) {
      this.Data.events?.emit(
        'TEMPLATE_UPDATED',
        docId,
        this.structure.get([this.structure.KEYS.TEMPLATE]),
        forceReRender,
      );
    }
  }

  getPageMeasures() {
    return this._template?.pageMeasures;
  }

  getTocDefinition() {
    return this._template?.toc;
  }

  getTolDefinition(label: string) {
    return this._template?.tols?.[label];
  }

  getHeaderRow(): boolean {
    return !!this._template?.headerRow;
  }

  setDocumentTemplate(template: string, removeInlineStyles: boolean = false) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'SET:DOCUMENT:TEMPLATE',
        {
          documentId: this.Data.context?.document?.id,
          template,
          removeInlineStyles,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
