import { Command } from '../Command';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';

export class EditCrossReferenceCommand extends Command {
  private options: Editor.Data.CrossReferences.PresentationTextOptionsType | undefined;

  constructor(
    context: Editor.Edition.Context,
    options?: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    super(context);
    this.options = options;
  }

  async handleExec() {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid Context');
    }

    const closestField = NodeUtils.closestOfTypeByPath(
      this.actionContext.baseData,
      this.actionContext.range.start.p,
      'f',
    );

    if (
      closestField &&
      NodeUtils.isFieldData(closestField.data) &&
      closestField.data.properties.t === 'cr'
    ) {
      if (this.options) {
        if (this.options.format != null) {
          this.actionContext.baseModel.set(
            [...closestField.path, 'properties', 'f'],
            this.options.format,
            {
              source: 'LOCAL_RENDER',
            },
          );
        }

        if (this.options.link != null) {
          this.actionContext.baseModel.set(
            [...closestField.path, 'properties', 'l'],
            this.options.link,
            {
              source: 'LOCAL_RENDER',
            },
          );
        }

        if (this.options.target != null) {
          this.actionContext.baseModel.set(
            [...closestField.path, 'properties', 'r'],
            this.options.target,
            {
              source: 'LOCAL_RENDER',
            },
          );
        }
      } else {
        this.options = {
          target: closestField.data.properties
            .r as Editor.Data.CrossReferences.PresentationTextOptionsType['target'],
          format: closestField.data.properties.f as Editor.Data.CrossReferences.PresentationFormat,
        };
      }

      this.options.origin = this.actionContext.baseModel.id;

      const text =
        await this.context.DataManager.crossReferences.getAsyncCrossReferencePresentationText(
          this.options,
        );

      if (text) {
        const textData = new NodeDataBuilder('text').setContent(text).build();
        if (textData) {
          this.actionContext.baseModel.set([...closestField.path, 'childNodes'], [textData], {
            source: 'LOCAL_RENDER',
          });
        }
      }

      this.actionContext.range.updateRangePositions({
        b: this.actionContext.baseModel.id,
        p: [...closestField.path, 'childNodes', 0],
      });
    }

    await this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
