/**
 * doDOC-UI configuration file
 *
 * This configuration is shared between doDOC's prod and dev configs.
 * For specific configurations to these two environments, please use the
 * files dodoc.config.dev and dodoc.config.prod
 */

const baseConfig: DodocConfig = {
  version: '',
  // Code and display name
  languages: [
    { code: 'en', name: 'English' },
    // { code: 'pt', name: 'Português' },
    { code: 'es', name: 'Español' },
    // { code: 'no', name: 'Norsk' },
    { code: 'fr', name: 'Français' },
    { code: 'de', name: 'Deutsch' },
    { code: 'zh-CN', name: '中文' },
    { code: 'ja', name: '日本語' },
  ],
  // Authentication server URL
  authority: 'http://localhost:8000',
  // Instance server URL
  api: 'http://localhost:8000',
  rt_rest: '',
  rt: 'localhost:8002',
  serverPort: '8000',
  serverWSPort: '8002',
  protos: {
    socketio: 'http://',
    sharedb: 'ws://',
    rest: 'http://',
  },
  locations: {
    socketio: '/rt/socketio',
    sharedb: '/rt/share',
    rest: '/rt/rest',
  },

  sentry: {
    dsn: 'https://bfb246db08cd8abc530ef72082068236@sentry.shared.ets-development.com/19',
    environment: 'local',
    app: 'ui',
  },

  editor: {
    blockPermissions: {
      access: 'View',
      edit: 'Pencil',
      comment: 'Comment',
      approve: 'Approve',
    },
  },

  // logger config
  logger: {
    level: null,
  },
};

/**
 * Returns the doDOC configuration object (we use a function here, and not
 * an export default of the config, to enable stubbing in unit-testing).
 */
/* istanbul ignore next */

export const getDoDOCAppConfig = async () => {
  let config;

  let serverConfig;
  try {
    const response = await fetch(`/settings.json?t=${Date.now()}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    if (response.headers.get('Content-Type')?.includes('application/json')) {
      serverConfig = await response.json();
    }
  } catch (error) {
    throw new Error('Invalid settings json file');
  }
  if (import.meta.env.MODE === 'production') {
    let partialConfig;
    try {
      partialConfig = await import('./runtime.prod.json');
    } catch (e) {
      partialConfig = {};
    }

    config = { ...baseConfig, ...partialConfig, ...global._dodocConfig, ...serverConfig };
  } else if (import.meta.env.MODE === 'test') {
    config = { ...baseConfig, ...global._dodocConfig, ...serverConfig };
  } else {
    /* develblock:start */
    let partialConfig;
    try {
      partialConfig = await import('./runtime.dev.json');
    } catch (e) {
      partialConfig = {};
    }

    config = { ...baseConfig, ...partialConfig, ...global._dodocConfig, ...serverConfig };

    const location = window.location;

    config.authority = `${location.protocol}//${location.hostname}:${config.serverPort}`;
    config.api = `${location.protocol}//${location.hostname}:${config.serverPort}`;
    config.rt = `${location.hostname}:${config.serverWSPort || config.serverPort}`;
    config.rt_rest = `${location.protocol}//${location.hostname}:${
      config.serverWSPort || config.serverPort
    }`;

    config.HASH = config.tenant;

    Object.defineProperty(config, 'tenant', {
      get() {
        const loc = window.location;
        if (loc.hash && loc.hash.startsWith('#ddct:')) {
          this.HASH = loc.hash.split('t:')[1];
          window.history.replaceState(null, '', ' ');
        }
        return this.HASH;
      },
    });
    /* develblock:end */
  }

  global._dodocAppConfig = config;

  return global._dodocAppConfig;
};

export const updateRTPort = (port: number) => {
  if (!global._dodocAppConfig) {
    global._dodocAppConfig = { ...baseConfig, ...global._dodocConfig };
  }
  global._dodocAppConfig.serverWSPort = `${port}`;
  const location = window.location;
  global._dodocAppConfig.rt = `${location.hostname}:${global._dodocAppConfig.serverWSPort || global._dodocAppConfig.serverPort}`;
  global._dodocAppConfig.rt_rest = `${location.protocol}//${location.hostname}:${
    global._dodocAppConfig.serverWSPort || global._dodocAppConfig.serverPort
  }`;
};

const getDodocConfig = (): typeof baseConfig => {
  if (global._dodocAppConfig) {
    return global._dodocAppConfig;
  }
  if (global._dodocConfig && Object.keys(global._dodocConfig).length > 0) {
    return global._dodocConfig;
  }
  return baseConfig;
};

export default getDodocConfig;
