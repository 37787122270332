import { Structure } from 'Editor/services/DataManager';
import { BlockViewModel } from '..';
import { BaseViewModel } from '../BaseViewModel';
import { DocumentLayout, PageLayout, WebLayout } from './Layout';
import { LazyLoaderManager, SectionValuesUpdateType } from './LazyLoaderManager';
import { ScrollManager } from './ScrollManager';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { Logger } from '_common/services';

type ExecutableTaskType<
  T extends (...args: any[]) => Promise<any> | any = (...args: any[]) => Promise<any> | any,
> = {
  task: T;
  params: Parameters<T>;
};

class ExecutableQueue {
  protected tasks: ExecutableTaskType[] = [];
  protected instance: any;
  protected running: boolean = false;

  constructor(instance: any) {
    this.instance = instance;
  }

  protected async runAll() {
    let task;
    this.running = true;
    while (this.tasks.length > 0) {
      task = this.tasks.shift();
      if (!task) {
        continue;
      }
      try {
        await task.task.call(this.instance, ...task.params);
      } catch (error) {
        console.log('Error running task');
      }
    }
    this.running = false;
  }

  start() {
    this.runAll();
  }

  clear() {
    this.tasks.splice(0, this.tasks.length);
  }

  enqueue<T extends (...args: any[]) => Promise<any> | any>(task: T, params: Parameters<T>) {
    this.tasks.push({
      task,
      params,
    });
    if (!this.running) {
      this.runAll();
    }
  }
}

export class DocumentViewModel extends BaseViewModel {
  typeName = 'DocumentViewModel';

  protected model: Structure;
  layout?: DocumentLayout;
  /* protected */ scrollManager: ScrollManager;
  /* protected */ lazyLoader: LazyLoaderManager;
  overflowCheckTimer: any = null;

  DEBUG: boolean = false;

  queue: ExecutableQueue;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);
    this.view = undefined;
    this.queue = new ExecutableQueue(this);
    this.queue.start();
    this.model = this.Data.models.get(this.Data.models.TYPE_NAME.STRUCTURE, `DS${id}`);

    this.handleBlockWindowReset = this.handleBlockWindowReset.bind(this);
    this.handleBlockWindowUpdate = this.handleBlockWindowUpdate.bind(this);
    this.handleBlockWindowUpdateDelta = this.handleBlockWindowUpdateDelta.bind(this);
    this.handleBlockWindowAnchorUpdate = this.handleBlockWindowAnchorUpdate.bind(this);
    this.handleSectionsValuesUpdate = this.handleSectionsValuesUpdate.bind(this);
    this.handleAnchorNotFound = this.handleAnchorNotFound.bind(this);
    this.handleUpdatedScrollAnchor = this.handleUpdatedScrollAnchor.bind(this);
    this.handleScrollReachedTop = this.handleScrollReachedTop.bind(this);
    this.handleScrollReachedBottom = this.handleScrollReachedBottom.bind(this);
    this.handleScrollReachedTopBottom = this.handleScrollReachedTopBottom.bind(this);
    this.handleSectionPropertiesUpdate = this.handleSectionPropertiesUpdate.bind(this);
    this.handleParagraphStyleUpdate = this.handleParagraphStyleUpdate.bind(this);
    this.handleDefaultTabStopUpdate = this.handleDefaultTabStopUpdate.bind(this);

    this.lazyLoader = new LazyLoaderManager(this.model);
    this.lazyLoader.on('RESET_WINDOW', () => {
      this.queue.clear();
      this.queue.enqueue(this.handleBlockWindowReset, [[]]);
    });
    this.lazyLoader.on('UPDATE_WINDOW', () => {
      this.queue.clear();
      this.queue.enqueue(this.handleBlockWindowUpdate, [[]]);
    });
    this.lazyLoader.on('UPDATE_ANCHOR', this.handleBlockWindowAnchorUpdate);
    this.lazyLoader.on('UPDATE_WINDOW_DELTA', (delta: Realtime.Core.RealtimeOps) => {
      logger.trace('UPDATE_WINDOW_DELTA');
      this.queue.enqueue(this.handleBlockWindowUpdateDelta, [delta]);
    });
    // TEMP: support old edition; might lead to dsync issues
    this.lazyLoader.on('UPDATE_WINDOW_DELTA_OLD', (delta: Realtime.Core.RealtimeOps) => {
      logger.trace('UPDATE_WINDOW_DELTA_OLD');
      this.handleBlockWindowUpdateDelta(delta);
    });
    this.lazyLoader.on('UPDATE_SECTIONS_VALUES', this.handleSectionsValuesUpdate);
    this.lazyLoader.on('UPDATE_DEFAULT_TAB_VALUE', this.handleDefaultTabStopUpdate);

    this.scrollManager = new ScrollManager(this.view);
    this.scrollManager.on('ANCHOR_NOT_FOUND', this.handleAnchorNotFound);
    this.scrollManager.on('UPDATED_ANCHOR', this.handleUpdatedScrollAnchor);
    this.scrollManager.on('REACHED_TOP', this.handleScrollReachedTop);
    this.scrollManager.on('REACHED_BOTTOM', this.handleScrollReachedBottom);
    this.scrollManager.on('REACHED_TOP_BOTTOM', this.handleScrollReachedTopBottom);

    this.Visualizer.hooks.afterSectionUpdate?.register(this.handleSectionPropertiesUpdate);
    this.Visualizer.hooks.afterParagraphStyleUpdate?.register(this.handleParagraphStyleUpdate);
  }

  debugMessage(message: string, ...args: any[]) {
    if (this.DEBUG) {
      Logger.trace(`[DocumentViewModel] ${message}`, ...args);
    }
  }

  private handleBlockWindowReset(nodeIds: string[]) {
    this.render();

    // rebuild widgets
    this.Visualizer.widgets?.rebuildWidgets();
    const blockWindow = this.lazyLoader.blockWindow;
    ReduxInterface.setBlockWindow({ start: blockWindow.start, end: blockWindow.end });
  }

  private handleBlockWindowUpdate(nodeIds: string[]) {
    this.render();

    // rebuild widgets
    this.Visualizer.widgets?.rebuildWidgets();
    const blockWindow = this.lazyLoader.blockWindow;
    ReduxInterface.setBlockWindow({ start: blockWindow.start, end: blockWindow.end });
  }

  private handleBlockWindowAnchorUpdate(anchorId: string) {
    this.scrollManager.setAnchorNode(anchorId);
  }

  private async handleBlockWindowUpdateDelta(delta: Realtime.Core.RealtimeOps) {
    let deltaElement;
    let vm;
    const length = delta.length;
    for (let index = 0; index < length; index++) {
      deltaElement = delta[index];
      if (deltaElement.ld !== undefined && deltaElement.li !== undefined) {
        vm = this.Visualizer.viewModelFactory?.get(deltaElement.li);
        if (vm) {
          await this.layout?.replaceChildAt(vm, deltaElement.p[0] as number);
        }
      } else if (deltaElement.ld !== undefined) {
        await this.layout?.removeChildAt(deltaElement.p[0] as number, deltaElement.ld as string);
      } else if (deltaElement.li !== undefined) {
        vm = this.Visualizer.viewModelFactory?.get(deltaElement.li);
        if (vm) {
          await this.layout?.insertChildAt(vm, deltaElement.p[0] as number);
        }
      }
    }
    this.scrollManager.setAnchorNode(this.lazyLoader.getWindowAnchor());

    // rebuild widgets
    this.Visualizer.widgets?.rebuildWidgets();
    const blockWindow = this.lazyLoader.blockWindow;
    ReduxInterface.setBlockWindow({ start: blockWindow.start, end: blockWindow.end });

    // this.scrollManager.checkScrollPosition();
  }

  private handleDefaultTabStopUpdate() {
    // this.layout?.updateSectionsValues(delta);
    this.tabulateAll();
  }

  private handleSectionsValuesUpdate(delta: SectionValuesUpdateType) {
    this.layout?.updateSectionsValues(delta);
  }

  private handleAnchorNotFound() {}

  private handleUpdatedScrollAnchor(anchorId: string) {
    this.lazyLoader.centerBlockWindowAt(anchorId);
  }

  private handleScrollReachedTop() {
    this.lazyLoader.requestWindowExtension('TOP');
  }

  private handleScrollReachedBottom() {
    this.lazyLoader.requestWindowExtension('BOTTOM');
  }

  private handleScrollReachedTopBottom() {
    this.lazyLoader.requestWindowExtension('BOTH');
  }

  private scheduleScrollPositionCheck() {
    if (!this.overflowCheckTimer) {
      this.overflowCheckTimer = setTimeout(() => {
        this.scrollManager.checkScrollPosition();
        this.overflowCheckTimer = null;
      }, 0);
    }
  }

  private handleSectionPropertiesUpdate(sectionId: string) {
    this.layout?.updateSectionProperties(sectionId);
  }

  private handleParagraphStyleUpdate(styleId: string) {
    this.layout?.updateSectionProperties(styleId);
  }

  async bindView(view: Editor.Visualizer.BaseView) {
    this.removeAllChildren();
    this.view = view;
    this.view.setAttribute('ispagenode', 'true');

    // set layout to document view
    if (this.view) {
      this.view.dataset.layout = this.Visualizer.layoutType;
    }

    this.scrollManager.bindView(this.view);

    this.setLayout();

    // await this.render();
  }

  protected setLayout(): void {
    if (this.layout) {
      this.layout.destroy();
    }
    switch (this.Visualizer.layoutType) {
      case 'PAGE':
        this.layout = new PageLayout(this.Data, this.Visualizer, this);
        this.lazyLoader.lockWindowStartAt(0);
        break;
      default:
        this.layout = new WebLayout(this.Data, this.Visualizer, this);
        this.lazyLoader.unlockWindowStart();
        break;
    }

    // set layout to document view
    if (this.view) {
      this.view.dataset.layout = this.Visualizer.layoutType;
    }
  }

  async changeLayout() {
    this.layout?.removeAllChildren();
    this.setLayout();

    this.Visualizer.widgets?.layoutChanged(this.Visualizer.layoutType);

    while (this.children.length) {
      this.children.shift()?.dispose();
    }

    const childNodes = this.lazyLoader.getBlockWindow();
    let vm: BlockViewModel | undefined;
    logger.trace('DOC VIEW MODEL render', childNodes, 'RENDER_MODE: ' + this.Visualizer.renderMode);
    for (let index = 0; index < childNodes.length; index++) {
      vm = this.Visualizer.viewModelFactory?.get(childNodes[index]) as BlockViewModel;
      if (vm) {
        await this.layout?.appendChild(vm);
      }
    }
    this.scrollManager.setAnchorNode(this.lazyLoader.getWindowAnchor());
    this.scheduleScrollPositionCheck();
  }

  async render() {
    this.debugMessage('render');
    if (this.view && this.layout) {
      this.layout.removeAllChildren();

      while (this.children.length) {
        this.children.shift()?.dispose();
      }
      const childNodes = this.lazyLoader.getBlockWindow();

      let vm: BlockViewModel | undefined;

      for (let index = 0; index < childNodes.length; index++) {
        vm = this.Visualizer.viewModelFactory?.get(childNodes[index]) as BlockViewModel;
        if (vm) {
          await this.layout.appendChild(vm, index === 0);
        }
      }

      this.scrollManager.setAnchorNode(this.lazyLoader.getWindowAnchor());
      this.scheduleScrollPositionCheck();

      // render selection
      this.Visualizer.selectionViewModel?.render(null);
    }
    return this.view;
  }

  async renderAt(blockId: string, childId?: string) {
    if (this.view && this.layout) {
      if (this.model.hasChildNode(blockId)) {
        this.scrollManager.stop();

        const windowData = this.lazyLoader.getCenteredBlockWindow(blockId);
        if (windowData) {
          this.lazyLoader.updateBlockWindow(windowData.blockWindow, windowData.anchorIndex, false);
        }

        this.layout.removeAllChildren();
        while (this.children.length) {
          this.children.shift()?.dispose();
        }

        const childNodes = this.lazyLoader.getBlockWindow();
        const indexOfBlock = childNodes.indexOf(blockId);

        let vm: BlockViewModel | undefined;

        for (let index = 0; index < childNodes.length; index++) {
          vm = this.Visualizer.viewModelFactory?.get(childNodes[index]) as BlockViewModel;
          if (vm) {
            await this.layout.appendChild(vm, index <= indexOfBlock);
          }
        }

        this.scrollManager.scrollIntoView(childId || blockId, 'MID');

        this.scrollManager.setAnchorNode(this.lazyLoader.getWindowAnchor());
        this.scheduleScrollPositionCheck();

        this.scrollManager.start();

        // render selection
        this.Visualizer.selectionViewModel?.render(null);
      } else {
        this.lazyLoader.resetBlockWindow();
        await this.render();
      }
    } else {
      throw new Error('DocumentViewModel not bound to view');
    }
  }

  isBlockRendered(blockId?: string, childId?: string) {
    if (blockId && this.model.hasChildNode(blockId)) {
      const vm = this.Visualizer.viewModelFactory?.get(blockId) as BlockViewModel;
      if (vm && vm?.isRendered) {
        return true;
      }
      return false;
    } else {
      this.lazyLoader.resetBlockWindow();
      throw new Error('Unable to render at node! ' + blockId);
    }
  }

  async centerWindowAt(blockId?: string, childId?: string) {
    if (blockId && this.model.hasChildNode(blockId)) {
      this.lazyLoader.centerBlockWindowAt(blockId);
      const vm = this.Visualizer.viewModelFactory?.get(blockId) as BlockViewModel;
      if (vm && !vm?.isRendered) {
        await vm.awaitForEvent('RENDERED');
      }
      this.scrollManager.scrollIntoView(childId || blockId, 'MID');
      return vm;
    } else {
      this.lazyLoader.resetBlockWindow();
      throw new Error('Unable to render at node! ' + blockId);
    }
  }

  getChild(index: number) {
    return this.children.getAtIndex(index);
  }

  getChildById(id: string) {
    return this.children.getById(id);
  }

  hasChild(presenter: BaseViewModel) {
    return this.children.includes(presenter);
  }

  indexOfChild(presenter: BaseViewModel) {
    return this.children.indexOf(presenter);
  }

  getScrollDiffToBottom() {
    return this.scrollManager.getScrollDiffToBottom();
  }

  reRenderBlock(nodeId: string) {
    const block = this.children.getById(nodeId) as BlockViewModel;
    if (block) {
      block.render(true);
    }
  }

  scrollIntoView(nodeId?: string, alignOption: string = 'CLOSEST', checkViewport: boolean = true) {
    return this.scrollManager?.scrollIntoView(nodeId, alignOption, checkViewport);
  }

  childChangedHeight(
    childModel: BlockViewModel,
    view: Editor.Visualizer.BaseView | null,
    difference: number,
    scrollDiff: number,
  ) {
    this.layout?.handleChildChangedHeight(childModel, view, difference, scrollDiff);
  }

  removeAllChildren() {
    while (this.children.length) {
      this.children.shift()?.dispose();
    }
    while (this.view?.firstChild) {
      this.view?.removeChild(this.view?.firstChild);
    }
  }

  //! TEST
  tabulateAll() {
    for (let index = 0; index < this.children.length; index++) {
      this.Visualizer.tabulator?.tabulate(this.children.getAtIndex(index) as BlockViewModel);
    }
  }

  dispose() {
    if (this.layout) {
      this.layout.destroy();
    }

    this.lazyLoader.destroy();
    this.scrollManager.destroy();
    this.removeAllChildren();
    if (this.parent) {
      this.parent.removeChild(this);
    }
    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
