import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import UploadManager from 'Editor/services/UploadManager/UploadManager';
import { Logger } from '_common/services';
import { ActionContext } from '../ActionContext';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import {
  ErrorCannotRemoveContent,
  ErrorElementNotEditable,
  ErrorCannotInsertElement,
  ErrorCommentCreation,
  ErrorInvalidPath,
} from '../Errors';
import { notify } from '_common/components/ToastSystem';

export abstract class Command<T extends any[] = any[]> {
  protected debug: boolean = false;

  protected context: Editor.Edition.Context;
  protected execOptions: Editor.Edition.CommandExecOptions = {
    createPatch: true,
    scrollIntoSelection: true,
    removePasteOptions: true,
  };

  protected actionContext?: Editor.Edition.ActionContext;

  constructor(context: Editor.Edition.Context, options?: Editor.Edition.CommandExecOptions) {
    this.context = context;
    this.execOptions = {
      ...this.execOptions,
      ...options,
    };
  }

  setActionContext(actionContext: Editor.Edition.ActionContext) {
    this.actionContext = actionContext;
  }

  setExecOptions(options: Editor.Edition.CommandExecOptions) {
    this.execOptions = {
      ...this.execOptions,
      ...options,
    };
  }

  protected buildActionContext(range?: Editor.Selection.JsonRange) {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      throw new Error('Invalid DataManager or selection');
    }

    let jsonRange: Editor.Selection.JsonRange;

    if (range) {
      jsonRange = range;
    } else {
      const rangeData = this.context.DataManager.selection.current;
      jsonRange = JsonRange.buildFromRangeData(rangeData[0]);
    }

    if (jsonRange) {
      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);

      const baseData = baseModel?.selectedData();

      if (!baseModel || !baseData || !baseData.id) {
        throw new Error('Invalid baseModel or baseData');
      }

      this.actionContext = new ActionContext(jsonRange, baseModel, baseData);
    }
  }

  protected applySelection(
    range: Editor.Selection.JsonRange | undefined = this.actionContext?.range,
    flag: boolean = true,
  ) {
    if (this.debug) {
      Logger.trace('Command applySelection', range, flag);
    }
    // apply new selection
    if (range && this.context.DataManager?.selection) {
      // TEMP: flag last selection
      if (flag) {
        this.context.DataManager.selection.history.flag('debounce');
      }
      this.context.DataManager.selection.setUserSelection([range.serializeToRangeData()]);
    }
  }

  protected createPatch() {
    // create patch
    this.context.DataManager?.history.createPatch();
  }

  protected askUserAboutThis() {
    return this.context.untrackedActionWarning();
  }

  protected uploadImage(
    image: File,
  ): Promise<Parameters<Parameters<typeof ReduxInterface.saveImage>[0]['callback']>[0]> {
    return new Promise((resolve, reject) => {
      if (this.context.DataManager && UploadManager.acceptImageFileSize(image)) {
        const documentId = this.context.DataManager.document.getDocumentId();
        if (documentId) {
          const callback: Parameters<typeof ReduxInterface.saveImage>[0]['callback'] = (
            imageData: Parameters<Parameters<typeof ReduxInterface.saveImage>[0]['callback']>[0],
          ) => {
            resolve(imageData);
          };

          const errorCallback: Parameters<typeof ReduxInterface.saveImage>[0]['errorCallback'] = (
            error,
          ) => {
            reject(error);
          };

          ReduxInterface.saveImage({
            params: {
              id: documentId,
              image,
            },
            callback,
            errorCallback,
          });
        } else {
          reject(new Error('Invalid document id!'));
        }
      } else {
        reject(new Error('Invalid image size!'));
      }
    });
  }

  protected isUserAuthor(data: Editor.Data.Node.TrackedData): boolean {
    try {
      const author = data.properties.author;

      if (author != null && this.context.DataManager?.users.isLoggedUser(author)) {
        return true;
      }
    } catch (error) {
      Logger.captureException(error);
    }
    return false;
  }

  // ----------------------------------------------------------------
  //                     suggestions functions
  // ----------------------------------------------------------------
  private getSuggestionRefInlineAncestors(
    baseData: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ) {
    const closestStartParagraph = NodeUtils.closestOfTypeByPath(baseData, startPath, ['p']);
    const closestEndParagraph = NodeUtils.closestOfTypeByPath(baseData, startPath, ['p']);

    if (!closestStartParagraph || !closestEndParagraph) {
      return;
    }

    let subStartPath = startPath.slice(closestStartParagraph.path.length);
    let subEndPath = endPath.slice(closestEndParagraph.path.length);

    const previousAncestor = NodeUtils.getPreviousAncestor(
      closestStartParagraph.data,
      subStartPath,
    );
    const nextAncestor = NodeUtils.getNextAncestor(closestEndParagraph.data, subEndPath);

    let currentAncestor: Editor.Data.Node.DataPathInfo | null = null;
    let currentSubPath: Editor.Selection.Path = [];
    if (previousAncestor) {
      currentAncestor = NodeUtils.getNextSibling(closestStartParagraph.data, previousAncestor.path);
      if (currentAncestor) {
        currentSubPath = startPath.slice(currentAncestor.path.length);
      }
    } else if (nextAncestor) {
      currentAncestor = NodeUtils.getPreviousSibling(closestEndParagraph.data, nextAncestor.path);
      if (currentAncestor) {
        currentSubPath = endPath.slice(currentAncestor.path.length);
      }
    }

    if (
      currentAncestor &&
      NodeUtils.isPathAtContentStart(currentAncestor.data, currentSubPath) &&
      previousAncestor
    ) {
      // check previous ancestor
      if (
        NodeUtils.isTrackedData(previousAncestor.data) &&
        this.isUserAuthor(previousAncestor.data)
      ) {
        return previousAncestor.data.properties.element_reference;
      }

      // check last child elements inside previous ancestor
      let length = previousAncestor.data.childNodes?.length || 0;
      let lastChild = previousAncestor.data.childNodes?.[length - 1];
      while (lastChild) {
        if (NodeUtils.isTrackedData(lastChild) && this.isUserAuthor(lastChild)) {
          return lastChild.properties.element_reference;
        }
        length = lastChild.childNodes?.length || 0;
        lastChild = lastChild.childNodes?.[length - 1];
      }
    }

    if (
      currentAncestor &&
      NodeUtils.isPathAtContentEnd(currentAncestor.data, currentSubPath) &&
      nextAncestor
    ) {
      // check next ancestor
      if (NodeUtils.isTrackedData(nextAncestor.data) && this.isUserAuthor(nextAncestor.data)) {
        return nextAncestor.data.properties.element_reference;
      }

      // check first child elements inside previous ancestor
      let firstChild = nextAncestor.data.childNodes?.[0];
      while (firstChild) {
        if (NodeUtils.isTrackedData(firstChild) && this.isUserAuthor(firstChild)) {
          return firstChild.properties.element_reference;
        }
        firstChild = firstChild.childNodes?.[0];
      }
    }
    return;
  }

  private getSuggestionRefBlockAncestors(
    baseModel: Editor.Data.Node.Model,
    baseData: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ) {
    const closestParagraph = NodeUtils.closestOfTypeByPath(baseData, endPath, ['p']);

    if (!closestParagraph) {
      return;
    }

    const subEndPath = endPath.slice(closestParagraph?.path.length);

    if (NodeUtils.isPathAtContentStart(closestParagraph.data, subEndPath)) {
      let previousData: Editor.Data.Node.Data | undefined | null;

      if (closestParagraph.data.id === baseModel.id) {
        // closest paragraph os base model
        const previousModel = this.context?.DataManager?.nodes.getPreviousModelById(baseModel.id);
        previousData = previousModel?.selectedData();
      } else {
        // closest paragraph inside container
        const previousDataInfo = NodeUtils.getPreviousAncestor(baseData, closestParagraph.path);
        previousData = previousDataInfo?.data;
      }

      if (previousData && previousData.childNodes) {
        const previousClosestStartTracked = NodeUtils.closestOfTypeByPath(
          previousData,
          ['childNodes', previousData.childNodes.length - 1],
          [ELEMENTS.TrackDeleteElement.ELEMENT_TYPE, ELEMENTS.TrackInsertElement.ELEMENT_TYPE],
        );

        if (
          previousClosestStartTracked &&
          NodeUtils.isTrackedData(previousClosestStartTracked.data) &&
          NodeUtils.isParagraphMarker(previousClosestStartTracked.data) &&
          this.isUserAuthor(previousClosestStartTracked.data)
        ) {
          return previousClosestStartTracked.data.properties.element_reference;
        }
      }
    }

    return;
  }

  protected getSuggestionRefFromContent() {
    if (!this.actionContext) {
      throw new Error('Invalid context');
    }

    const baseModel = this.actionContext.baseModel;
    const baseData = this.actionContext.baseData;
    const range = this.actionContext.range;

    // check parent ancestors
    // check closes start and end
    const closestStartTracked = NodeUtils.closestOfTypeByPath(baseData, range.start.p, [
      ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ]);

    const closestEndTracked = NodeUtils.closestOfTypeByPath(baseData, range.end.p, [
      ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ]);

    if (
      closestStartTracked &&
      NodeUtils.isTrackedData(closestStartTracked.data) &&
      this.isUserAuthor(closestStartTracked.data)
    ) {
      this.actionContext.suggestionRef = closestStartTracked.data.properties.element_reference;
      return;
    }

    if (
      closestEndTracked &&
      NodeUtils.isTrackedData(closestEndTracked.data) &&
      this.isUserAuthor(closestEndTracked.data)
    ) {
      this.actionContext.suggestionRef = closestEndTracked.data.properties.element_reference;
      return;
    }

    // check inline sibling ancestors
    const inlineRef = this.getSuggestionRefInlineAncestors(baseData, range.start.p, range.end.p);
    if (inlineRef != null) {
      this.actionContext.suggestionRef = inlineRef;
      return;
    }

    // check block sibling ancestors if caret is at start
    const blockRef = this.getSuggestionRefBlockAncestors(
      baseModel,
      baseData,
      range.start.p,
      range.end.p,
    );
    if (blockRef != null) {
      this.actionContext.suggestionRef = blockRef;
      return;
    }

    return;
  }

  protected getSuggestionContent(
    suggestionData: Editor.Data.Node.Data[],
  ): Editor.Data.Suggestions.SuggestionContentData | undefined {
    let result: Editor.Data.Suggestions.SuggestionContentData | undefined = undefined;

    for (let i = 0; i < suggestionData.length; i++) {
      let type: Editor.Data.Suggestions.SuggestionContentData['type'] = null;
      let content: string = '';

      if (NodeUtils.isParagraphMarker(suggestionData[i])) {
        content = '\n';
        type = 'text';
      } else {
        const queryResult = NodeUtils.querySelectorInData(
          suggestionData[i],
          [
            ELEMENTS.Text.ELEMENT_TYPE,
            ELEMENTS.TableElement.ELEMENT_TYPE,
            ELEMENTS.FigureElement.ELEMENT_TYPE,
            ...NodeUtils.INLINE_NON_EDITABLE_TYPES,
            ...NodeUtils.BLOCK_NON_EDITABLE_TYPES,
            // ...NodeUtils.INLINE_WRAP_TYPES,
            // ELEMENTS.HyperlinkElement.ELEMENT_TYPE
          ],
          undefined,
          { onlyFirstChild: true },
        );

        for (let i = 0; i < queryResult.length; i++) {
          const element = queryResult[i];
          switch (element.data.type) {
            case 'figure':
            case 'image-element':
            case 'img':
              if (type !== 'text') {
                type = 'figure';
              }
              break;
            case 'pb':
              type = 'text';
              content += 'Page Break';
              break;
            case 'sb':
              type = 'text';
              content += 'Section Break';
              break;
            case 'cb':
              type = 'text';
              content += 'Column Break';
              break;
            case 'text':
              type = 'text';
              content += element.data.content;
              break;
            default:
              if (type !== 'text') {
                type = element.data.type;
              }
              break;
          }
        }
      }

      if (type != null) {
        if (result) {
          if (result.type === 'text' && type === 'text') {
            result.value += content;
          } else if (type === 'text') {
            result.type = type;
            result.value = content;
          } else if (result.type !== 'text' && result.type !== type) {
            result.type = null;
            result.value = null;
          }
        } else {
          result = {
            type: type,
            value: content,
          };
        }
      }
    }

    return result;
  }

  protected async getSuggestionData(
    ctx: Editor.Edition.ActionContext,
  ): Promise<Editor.Edition.SuggestionData | undefined> {
    if (!this.context.DataManager) {
      return undefined;
    }

    let blockIds: string[] = [];

    // get block ids from context
    for (let s = 0; s < ctx.suggestionLocations.length; s++) {
      if (!blockIds.includes(ctx.suggestionLocations[s].blockId)) {
        blockIds.push(ctx.suggestionLocations[s].blockId);
      }
    }

    // get block ids from other suggestion locations
    const suggestionLocations =
      (await this.context.DataManager.suggestions?.getTrackedActionLocation(ctx.suggestionRef)) ||
      [];
    for (let s = 0; s < suggestionLocations.length; s++) {
      if (!blockIds.includes(suggestionLocations[s].level0)) {
        blockIds.push(suggestionLocations[s].level0);
      }
    }

    // remove duplicates (if they exist)
    blockIds = blockIds.filter((value, index, self) => self.indexOf(value) === index);

    // sort block ids
    blockIds = blockIds.sort((a, b) => {
      const aIndex = this.context.DataManager?.structure.getIndexOfNode(a) || 0;
      const bIndex = this.context.DataManager?.structure.getIndexOfNode(b) || 0;
      return aIndex - bIndex;
    });

    if (blockIds.length) {
      const trackedInsert: Editor.Data.Node.Data[] = [];
      const trackedDelete: Editor.Data.Node.Data[] = [];

      // filter inserted and deleted suggestions
      for (let b = 0; b < blockIds.length; b++) {
        const data = this.context.DataManager.nodes.getNodeModelById(blockIds[b])?.selectedData();

        if (data) {
          const result = NodeUtils.querySelectorInData(data, ['tracked-insert', 'tracked-delete'], {
            element_reference: ctx.suggestionRef,
          });

          for (let i = 0; i < result.length; i++) {
            if (result[i].data.type === 'tracked-insert') {
              trackedInsert.push(result[i].data);
            }

            if (result[i].data.type === 'tracked-delete') {
              trackedDelete.push(result[i].data);
            }
          }
        }
      }

      let insertedContent = this.getSuggestionContent(trackedInsert);
      let deletedContent = this.getSuggestionContent(trackedDelete);

      let type: Editor.Edition.SuggestionData['type'] = 'INSERT';

      if (insertedContent && deletedContent) {
        type = 'REPLACE';
      } else if (insertedContent) {
        type = 'INSERT';
      } else if (deletedContent) {
        type = 'DELETE';
      }

      return {
        type,
        insertedContent,
        deletedContent,
      };

      // TODO return suggestion data
      // add suggestions for update
      // ctx.addSuggestionContent(
      //   trackedData.data.properties.element_reference,
      //   insertedContent,
      //   deletedContent,
      // );
    }

    return undefined;
  }

  protected async handleSuggestionsUpdate() {
    if (this.debug) {
      Logger.trace('Command handleSuggestionsUpdate', this.actionContext);
    }

    if (!this.context.DataManager?.suggestions || !this.actionContext) {
      throw new Error('Invalid context');
    }

    const refId = this.actionContext.suggestionRef;
    const suggestionData = await this.getSuggestionData(this.actionContext);

    if (suggestionData) {
      if (this.context.DataManager.suggestions.isSuggestionCreated(refId)) {
        // update suggestion
        this.context.DataManager.suggestions.updateSuggestionType(refId, suggestionData.type);
        this.context.DataManager.suggestions.updateSuggestionContent(refId, {
          inserted: suggestionData.insertedContent || { type: null, value: null },
          deleted: suggestionData.deletedContent || { type: null, value: null },
        });
      } else {
        // create suggestion
        this.context.DataManager.suggestions.addTrackedAction(
          suggestionData.type,
          suggestionData.insertedContent || { type: null, value: null },
          suggestionData.deletedContent || { type: null, value: null },
          [],
          refId,
        );
      }
    }
  }

  protected handleErrors = (error: any) => {
    switch (true) {
      case error instanceof ErrorElementNotEditable:
        return notify({
          type: 'error',
          title: 'global.error',
          message: 'editor.errors.selectionNonEditable',
        });
      case error instanceof ErrorCannotRemoveContent:
        return notify({
          type: 'warning',
          title: 'global.warning',
          message: 'editor.errors.cut',
        });
      case error instanceof ErrorCannotInsertElement:
        return notify({
          type: 'error',
          title: 'global.error',
          message: 'ERROR.ERROR_INSERTING_ELEMENT',
        });
      case error instanceof ErrorCommentCreation:
        return notify({
          type: 'error',
          title: 'global.error',
          message: 'editor.errors.comments.currentSelectionInvalid',
        });
      case error instanceof ErrorInvalidPath:
        Logger.captureException(error);
        break;
      default: {
        Logger.captureException(error);
        notify({
          type: 'error',
          title: 'global.error',
          message: 'global.error',
        });
      }
    }
  };

  // ----------------------------------------------------------------
  //                        exec functions
  // ----------------------------------------------------------------
  protected abstract handleExec(...args: T): Promise<void>;

  async exec(...args: T): Promise<Editor.Edition.Command> {
    // check if is rendered
    if (this.context.navigationManager?.isMarkerRendered()) {
      // stop selectionTracker ?
      // TODO: check if this is needed
      // this.context.VisualizerManager?.selection.stopSelectionTracker();

      try {
        // scroll into selection
        if (this.execOptions.scrollIntoSelection) {
          this.context.navigationManager?.scrollIntoSelection();
        }

        // remove paste options
        if (this.execOptions.removePasteOptions) {
          this.context.clipboard?.removePasteOptions();
        }

        // execute command
        await this.handleExec(...args);
      } catch (error) {
        this.handleErrors(error);
        // TODO restore changes on error ?
      } finally {
        // start selection tracker
        // TODO: check if this is needed
        // this.context.VisualizerManager?.selection.debounceStartSelectionTracker();
      }
    }

    return this;
  }
}
