import { BaseTypedEmitter } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { Doc, Query } from 'sharedb';
import {
  IndexableElementType,
  IndexableElementTypeName,
  IndexableType,
  TypeName,
} from './Models.types';

const COLLECTION_NAME: Partial<{
  [index in TypeName]: string;
}> = {
  SECTION: 'sections',
  NODE: 'nodes',
  TASK: 'tasks',
};

export type IndexerDeltaType<T> = {
  in: T;
  out: T;
  changedOrder: boolean;
};

type IndexerEvents<K = any> = {
  ERROR: (error: Error) => void;
  READY: () => void;
  LOADED: (data: K[]) => void;
  INSERTED: (inserted: K[]) => void;
  REMOVED: (removed: K[]) => void;
  CHANGED: (data: K[]) => void;
  CHANGED_DELTA: (data: IndexerDeltaType<any>) => void;
};

export class ModelIndexer<
  T extends IndexableElementTypeName,
  K extends IndexableType = IndexableElementType[T],
> extends BaseTypedEmitter<IndexerEvents<K>> {
  protected version: {
    version: any;
    results?: K[];
    [index: string]: any;
  } | null = null;
  protected typeName: TypeName;
  protected qs: any;
  protected q: Query | undefined;
  protected transport: Transport;
  protected models: Editor.Data.Models.Controller;
  protected qResults: Doc[] = [];
  results: K[] = [];
  ready: boolean = false;

  protected pendingEvents: { [index in keyof IndexerEvents]?: any[] } = {};

  constructor(transport: Transport, models: Editor.Data.Models.Controller, typeName: T) {
    super();
    this.transport = transport;
    this.typeName = typeName;
    this.models = models;
    this.handleQueryReady = this.handleQueryReady.bind(this);
    this.handleQueryInsertedElements = this.handleQueryInsertedElements.bind(this);
    this.handleQueryRemovedElements = this.handleQueryRemovedElements.bind(this);
    this.handleQueryElementsChanged = this.handleQueryElementsChanged.bind(this);
    this.handleQueryError = this.handleQueryError.bind(this);
  }

  protected static diffInOut<T>(
    arrayA: T[],
    arrayB: T[],
    verifyOrder = false,
  ): IndexerDeltaType<T[]> {
    const arrayIntersection = arrayA.filter((value) => arrayB.includes(value));
    const goingOut = arrayA.filter((x) => !arrayIntersection.includes(x));
    const goingIn = arrayB.filter((x) => !arrayIntersection.includes(x));
    let changedOrder = false;
    if (verifyOrder && (!goingOut.length || !goingIn.length)) {
      const length = Math.max(arrayA.length, arrayB.length);
      for (let index = 0; index < length; index++) {
        if (arrayA[index] !== arrayB[index]) {
          changedOrder = true;
          break;
        }
      }
    }
    return {
      in: goingIn,
      out: goingOut,
      changedOrder,
    };
  }

  start(qs: any) {
    this.qs = qs;
    if (!this.qs) {
      return;
    }
    this.cancelQuery();
    this.q = this.transport.createSubscribeQuery(COLLECTION_NAME[this.typeName], this.qs, {
      results: this.qResults,
    });

    this.handleEvents();
  }

  private handleEvents() {
    this.q?.on('ready', this.handleQueryReady);
    this.q?.on('changed', this.handleQueryElementsChanged);
    this.q?.on('insert', this.handleQueryInsertedElements);
    this.q?.on('remove', this.handleQueryRemovedElements);
    this.q?.on('error', this.handleQueryError);

    if (this.q?.ready) {
      this.handleQueryReady();
    }
  }

  private removeEvents() {
    this.q?.off('ready', this.handleQueryReady);
    this.q?.off('changed', this.handleQueryElementsChanged);
    this.q?.off('insert', this.handleQueryInsertedElements);
    this.q?.off('remove', this.handleQueryRemovedElements);
    this.q?.off('error', this.handleQueryError);
  }

  handleQueryReady() {
    if (this.q?.results) {
      this.qResults = this.q.results;
      this.results = this.q.results.map((doc) => {
        return this.models.get(this.typeName, doc);
      });
    }
    this.ready = true;
    !this.version && this.triggerLoaded(this.results);
  }

  handleQueryInsertedElements(docs: Doc[], index: number) {
    //
    let newDocs: K[] = docs.map((doc) => {
      return this.models.get(this.typeName, doc);
    });
    this.results.splice(index, 0, ...newDocs);
    !this.version && this.emit('INSERTED', newDocs);
  }

  handleQueryRemovedElements(docs: Doc[], index: number) {
    //
    let oldDocs: K[] = this.results.splice(index, docs.length);
    !this.version && this.emit('REMOVED', oldDocs);
  }

  handleQueryElementsChanged(docs: Doc[]) {
    !this.version && this.emit('CHANGED', this.results);
  }

  handleQueryError(error: any) {
    this.emit('ERROR', error as Error);
    this.destroy();
  }

  setVersionData(version: ApiSchemas['VersionsSchema'] | null, results?: any) {
    //
    if (version) {
      this.version = {
        version,
        results: (results || []).map((doc: string) => {
          return this.models.get(this.typeName, doc);
        }),
      };
      this.triggerLoaded(this.version.results || []);
    } else {
      this.version = null;
      this.triggerLoaded(this.results);
    }
  }

  protected cancelQuery() {
    this.ready = false;
    if (this.q) {
      this.removeEvents();
      this.q.destroy();
      this.q = undefined;
    }
  }

  protected triggerLoaded(results: K[]) {
    this.emit('LOADED', results);
    this.triggerPendingEvent('LOADED');
  }

  awaitForReady(): Promise<void> {
    if (this.ready) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      if (!this.pendingEvents['LOADED']) {
        this.pendingEvents['LOADED'] = [];
      }
      this.pendingEvents['LOADED'].push(resolve);
    });
  }

  protected triggerPendingEvent(eventName: keyof IndexerEvents) {
    if (this.pendingEvents[eventName]) {
      for (let index = 0; index < this.pendingEvents[eventName].length; index++) {
        this.pendingEvents[eventName][index]();
      }
    }
  }

  destroy() {
    super.destroy();
    this.cancelQuery();
  }
}
