import { BlockViewModel, DocumentViewModel } from '../..';
import { BaseViewModel } from '../../BaseViewModel';
import { SectionValuesUpdateType } from '../LazyLoaderManager';

abstract class BaseLayout<T extends BaseViewModel> {
  protected vm: T;
  protected Visualizer: Partial<Editor.Visualizer.State>;
  protected Data: Editor.Data.API;

  constructor(Data: Editor.Data.API, Visualizer: Partial<Editor.Visualizer.State>, vm: T) {
    this.vm = vm;
    this.Visualizer = Visualizer;
    this.Data = Data;
  }

  abstract handleChildChangedHeight(
    viewModel: BlockViewModel,
    view: Editor.Visualizer.BaseView | null,
    difference: number,
    scrollDiff: number,
  ): void;

  abstract updateSectionsValues(delta: SectionValuesUpdateType): void;

  abstract updateSectionProperties(sectionId: string): void;

  abstract updateParagraphStyle(styleId: string): void;

  abstract appendChild(viewModel: BaseViewModel, awaitForLoaded?: boolean): Promise<void>;

  abstract replaceChildAt(viewModel: BaseViewModel, index: number): void;

  abstract insertChildAt(viewModel: BaseViewModel, index: number): void;

  abstract removeChildAt(index: number, blockId: string): void;

  abstract removeAllChildren(): void;

  abstract destroy(): void;
}

export abstract class DocumentLayout extends BaseLayout<DocumentViewModel> {}
