import { Logger } from '_common/services';
import { Command } from '../Command';
import { SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { ErrorElementNotEditable } from '../../Errors';

export class InsertInlineCommand extends Command {
  // constructor(context: Editor.Edition.Context) {
  //   super(context);
  //   // this.elementData = elementData;
  // }

  protected handleTextElement(
    ctx: Editor.Edition.ActionContext,
    elementToInsert: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
  ): boolean {
    if (!ctx.baseModel || !ctx.baseData) {
      return false;
    }

    // TODO: some of this code needs to be moved to manipulator

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        forceTextAsWrap: !!options.forceTextAsWrap,
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
      },
      this.context.DataManager,
    );

    let pathToInsert: Editor.Selection.Path = ctx.range.start.p;
    let pathToUpdate: Editor.Selection.Path | undefined;

    let parentChildData = NodeUtils.getParentChildInfoByPath(ctx.baseData, pathToInsert);

    if (!parentChildData?.parentData) {
      return false;
    }

    let isAtStart =
      parentChildData.contentIndex === 0 ||
      (parentChildData.childIndex === 0 && isNaN(parentChildData.contentIndex));

    let isAtEnd =
      (NodeUtils.isTextData(parentChildData.childData) &&
        parentChildData.contentIndex === parentChildData.childData.content.length) ||
      (parentChildData.parentData.childNodes &&
        parentChildData.childIndex === parentChildData.parentData.childNodes.length &&
        isNaN(parentChildData.contentIndex));

    if (NodeUtils.isCitationData(elementToInsert)) {
      // adjust path for citations
      let closestAncestor = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
        'citations-group',
      ]);

      if (!closestAncestor && (isAtStart || isAtEnd)) {
        closestAncestor = NodeUtils.closestSiblingAncestorOfType(
          ctx.baseData,
          ctx.range.start.p,
          ['citations-group'],
          ['tracked-insert'],
        );
      }

      if (closestAncestor && NodeUtils.isCitationsGroupData(closestAncestor.data)) {
        pathToInsert = [
          ...closestAncestor.path,
          'childNodes',
          closestAncestor.data.childNodes?.length || 0,
        ];

        pathToUpdate = closestAncestor.path;
        let childOffset = Number(pathToUpdate[pathToUpdate.length - 1]);
        if (!isNaN(childOffset)) {
          pathToUpdate[pathToUpdate.length - 1] = childOffset + 1;
        }
      } else {
        // if no citation group found
        let citationGroupData = new NodeDataBuilder('citations-group')
          .addChildData(JSON.parse(JSON.stringify(elementToInsert)))
          .build();

        if (citationGroupData) {
          elementToInsert = citationGroupData;
        }
      }
    }

    parentChildData = NodeUtils.getParentChildInfoByPath(ctx.baseData, pathToInsert);

    if (!parentChildData?.parentData) {
      return false;
    }

    // is insertion allowed and element restrictions
    if (
      !NodeUtils.isAllowedUnder(parentChildData.parentData.type, elementToInsert.type) ||
      NodeUtils.isRestrictedUnder(ctx.baseData.type, elementToInsert.type)
    ) {
      throw new Error(
        `Element insertion not allowed!! ${ctx.baseData.type} ${parentChildData.parentData.type} ${elementToInsert.type}`,
      );
    }

    if (this.context.contentManipulator) {
      let insertContentOptions: Editor.Edition.InsertContentOptions = {};

      if (options.forceBlockSplit) {
        // force block split
        pathToUpdate = this.context.contentManipulator.splitBlockContent(ctx, pathToInsert);
        if (!pathToUpdate) {
          return false;
        }
      } else if (options.forceInlineSplit) {
        // force inline styles split
        let closestText = NodeUtils.closestOfTypeByPath(
          ctx.baseData,
          ctx.range.start.p,
          NodeUtils.BLOCK_TEXT_TYPES,
        );

        if (closestText) {
          let resultPath = this.context.contentManipulator.splitInlineContent(
            ctx.baseModel,
            closestText.data,
            closestText.path,
            ctx.range.start.p,
          );

          if (resultPath) {
            pathToInsert = resultPath;
          }
        }
      }

      if (
        this.context.contentManipulator.insertContent(
          ctx,
          pathToInsert,
          elementToInsert,
          insertContentOptions,
        )
      ) {
        if (pathToUpdate) {
          ctx.range.updateRangePositions({
            b: ctx.range.start.b,
            p: pathToUpdate,
          });
        }
        return true;
      }
    }

    return false;
  }

  protected handleCollapsedSelection(
    ctx: Editor.Edition.ActionContext,
    elementToInsert: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
  ): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    ctx.setModelAndData(baseModel, baseData);

    const result = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_EDITABLE_TYPES,
    ]);

    if (result && NodeUtils.isBlockTextData(result.data)) {
      // text elements

      return this.handleTextElement(ctx, elementToInsert, options);
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        forceTextAsWrap: true,
      },
      this.context.DataManager,
    );

    // remove content
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  protected async handleExec(
    elementToInsert?: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
    // ctx?: Editor.Edition.ActionContext,
  ): Promise<void> {
    if (this.debug) {
      Logger.trace('InsertInlineCommand exec', this);
    }

    if (!this.actionContext) {
      this.buildActionContext();

      this.getSuggestionRefFromContent();
    }

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.actionContext ||
      !elementToInsert
    ) {
      throw new Error('Invalid context');
    }

    // handle non collapsed selection
    if (!this.actionContext.range.collapsed) {
      if (!this.handleNonCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      if (!this.handleCollapsedSelection(this.actionContext, elementToInsert, options)) {
        return;
      }
    }

    if (this.execOptions.createPatch) {
      await this.handleSuggestionsUpdate();

      this.applySelection();

      this.createPatch();
    }
  }
}
