import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import { notify } from '_common/components/ToastSystem';
import { EditorSelectionUtils, JsonRange } from '../_Common/Selection';
import { EditorDOMUtils } from '../_Common/DOM';

type CaretPosition = {
  position?: DoDOCSelection.CaretPosition;
  offset?: number;
};

class NavigationManager {
  private editorContext: Editor.Context;
  private jumpToSelectionNotificationTimeout: NodeJS.Timeout | undefined;

  constructor(editorContext: Editor.Context) {
    this.jumpToSelectionNotificationTimeout = undefined;
    this.editorContext = editorContext;

    this.renderDocumentAtMarker = this.renderDocumentAtMarker.bind(this);
  }

  destroy() {}

  /**
   * scrolls page to specific element
   * @param {*} level0Id level 0 id to render at
   * @param {*=} elementId element id to scroll into
   * @param {Function=} callback
   * @deprecated
   */
  renderDocumentAtId(level0Id: string, elementId?: string, caretPosition: CaretPosition = {}) {
    return new Promise<void>((resolve, reject) => {
      const { position = 'INSIDE_START', offset } = caretPosition;

      if (level0Id) {
        if (!elementId) {
          elementId = level0Id;
        }

        ReduxInterface.startEditorLoading();
        setTimeout(async () => {
          try {
            this.editorContext.visualizerManager?.selection.stopSelectionTracker();
            await this.editorContext?.visualizerManager?.centerAtBlock(level0Id, elementId);
            setTimeout(() => {
              const node = EditorDOMUtils.getNode(elementId, this.editorContext?.documentContainer);
              if (node) {
                EditorSelectionUtils.setCaret(node, position, offset);
                this.editorContext.visualizerManager?.selection.triggerSelectionChanged();
              }
              ReduxInterface.stopEditorLoading();
              this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
              resolve();
            }, 100);
          } catch (error) {
            ReduxInterface.stopEditorLoading();
            this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
            reject(error);
          }
        }, 0);
      } else {
        reject(new Error('Block id undefined!'));
      }
    });
  }

  /**
   * render document at marker
   * @param {*} marker
   * @param {Function=} callback
   * @deprecated
   */
  async renderDocumentAtMarker(
    marker?: Editor.Selection.RangeData[],
    callback?: (error?: unknown) => void,
  ) {
    if (marker) {
      try {
        await this.editorContext?.visualizerManager?.centerAtBlock(marker[0].start.b);

        this.editorContext?.DataManager?.selection?.restore();

        if (callback) {
          callback();
        }
      } catch (error: unknown) {
        Logger.captureException(error);
        if (callback) {
          callback(error);
        }
      }
    } else {
      throw new Error('Marker undefined!');
    }
  }

  /**
   * check if last marker is rendered on the page
   */
  isMarkerRendered(
    rangesData = this.editorContext?.DataManager?.selection?.history.last,
    showNotification = true,
  ) {
    try {
      if (rangesData != null) {
        if (rangesData[0]) {
          const jsonRange = JsonRange.buildFromRangeData(rangesData[0]);

          try {
            jsonRange.serializeToDOMRange();

            return true;
          } catch (e) {
            Logger.captureException(e);
            if (this.jumpToSelectionNotificationTimeout) {
              clearTimeout(this.jumpToSelectionNotificationTimeout);
              this.jumpToSelectionNotificationTimeout = undefined;
            }

            if (showNotification === true) {
              this.jumpToSelectionNotificationTimeout = setTimeout(() => {
                // marker its not rendered
                notify({
                  type: 'info',
                  title: 'SELECTION_LOST_TITLE',
                  message: 'SELECTION_LOST_MESSAGE',
                  footerContent: {
                    label: 'PROCEED',
                    callback: () => this.renderDocumentAtMarker(rangesData),
                  },
                });
              }, 250);
            }
          }
        }
      } else {
        throw new Error('Block id undefined!');
      }
    } catch (e) {
      Logger.captureException(e);
    }
    return false;
  }

  /**
   * scrolls into last marker
   * if marker its not rendered, renders marker and scroll into it
   * if its rendered, just scrolls into it
   */

  scrollIntoSelection(
    rangeData?: Editor.Selection.RangeData[],
    showNotification?: boolean,
    force?: any,
  ) {
    try {
      // if last selection marker is rendered
      // if (this.isMarkerRendered(markerToRestore, showNotification)) {
      if (!this.editorContext?.selectionManager?.isSelectionInPage() || rangeData != null) {
        // if (
        //   !this.editorContext.selectionManager.restore({
        //     marker: markerToRestore,
        //     showNotification,
        //     force,
        //   })
        // ) {
        //   return false;
        // }

        if (rangeData) {
          this.editorContext?.DataManager?.selection?.setUserSelection(rangeData);
        } else {
          this.editorContext?.DataManager?.selection?.restore();
        }
      }

      const selection = EditorSelectionUtils.getSelection();

      if (selection) {
        let nodeToCheck;
        let offsetToCheck;

        if (!EditorSelectionUtils.isSelectionCollapsed()) {
          nodeToCheck = selection.focusNode;
          offsetToCheck = selection.focusOffset - 1;
        } else {
          nodeToCheck = selection.anchorNode;
          offsetToCheck = selection.anchorOffset - 1;
        }

        if (nodeToCheck && nodeToCheck === this.editorContext.documentContainer) {
          // if selection is in the page

          if (offsetToCheck < 0) {
            offsetToCheck = 0;
          }
          nodeToCheck = nodeToCheck.childNodes[offsetToCheck];
        } else {
          // if selection is a node within page
          while (
            nodeToCheck &&
            nodeToCheck.nodeType === Node.TEXT_NODE &&
            nodeToCheck.parentNode !== this.editorContext.documentContainer
          ) {
            nodeToCheck = nodeToCheck.parentNode;
          }
        }

        if (nodeToCheck instanceof Element) {
          this.editorContext?.visualizerManager?.scrollIntoView(nodeToCheck.id, 'MID');
        }

        return true;
      }
      // }
    } catch (error) {
      Logger.captureException(error);
    }

    return false;
  }
}

export default NavigationManager;
