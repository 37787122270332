import { BaseTypedEmitter } from '_common/services/Realtime';
import { CitationsLibraryData, TaskData, TasksData, SuggestionData } from '../../models';

export interface DataManagerEvents {
  READY: () => void;
  NOT_READY: () => void;

  UPDATE_OBJECT_STATUS: (
    documentId: Realtime.Core.Document.Data['_id'],
    status: Realtime.Core.Document.Data['status'],
    statusInfo: Realtime.Core.Document.Data['statusInfo'],
    reRender?: boolean,
  ) => void;

  //
  USER_JOINED: (user: string) => void;
  USER_LEFT: (user: string) => void;
  LOAD_USERS_ONLINE: (users: string[]) => void;
  //
  LOAD_CITATIONS_LIBRARY: (library: CitationsLibraryData) => void;
  LOAD_DOCUMENT_CITATIONS: (data: any) => void;
  LOAD_DOCUMENT_REFERENCE_STYLE: (refStyleId: string) => void;
  LOAD_DOCUMENT_STYLES: (data: any) => void;
  LOAD_LIST_STYLES: (data: any) => void;
  DOCUMENT_STYLES_DELETE_RESPONSE: (data: any) => void;
  DOCUMENT_STYLES_RESET_RESPONSE: (data: any) => void;
  SET_DOCUMENT_TEMPLATE_FINISHED: () => void;

  // structure controller
  ADDED_BLOCK: (operationContext: Realtime.Core.OperationContext) => void;
  REMOVED_BLOCK: (operationContext: Realtime.Core.OperationContext) => void;

  // document controller
  DOCUMENT_UPDATED: (documentData: Realtime.Core.Document.Data) => void;
  UPDATE_TRACK_LOCK_STATUS: () => void;

  // comment controller
  CANCEL_TEMPORARY_COMMENT: () => void;
  OPEN_TEMPORARY_COMMENT: (reference: any) => void;
  UPDATE_COMMENT: (data: Comments.CommentData | null) => void;
  LOAD_COMMENTS_DATA: (commentsList: string[], commentsData: Comments.CommentsData) => void;
  CANCEL_TEMNTS_DATA: (commentsList: string[], commentsData: Comments.CommentsData) => void;

  // suggestions controller
  CREATE_SUGGESTION: (data: SuggestionData | null) => void;

  // tasks controller
  UPDATE_TASK: (data: TaskData | null) => void;
  LOAD_TASKS_DATA: (tasksList: string[], tasksData: TasksData) => void;

  // notes controller
  UPDATE_NOTE: (data: Required<Notes.NoteData>) => void;
  LOAD_NOTES_DATA: (payload: Notes.NotesPayload) => void;
  //languages
  LOAD_LANGUAGES: (data: { languages: Services.Language[]; defaultLanguage: string }) => void;

  //approvals
  LOAD_APPROVAL_SUMMARY: (summary: Approval.ApprovalSummary) => void;

  //permissions
  DESELECT_ALL_FOR_PERMISSIONS: () => void;
  SELECT_ALL_FOR_PERMISSIONS: (blocks: string[]) => void;
  LOAD_PERMISSIONS_SUMMARY: (summary: Permissions.PermissionSummary) => void;

  LOAD_TABLE_OF_CONTENTS: (data: TableOfContents.TOCStructureDataType) => void;

  // Template
  TEMPLATE_UPDATED: (documentId: string, templateId: string, forceReRender: boolean) => void;

  // PROOFING
  UPDATE_WORD_COUNT: (
    summary: Editor.Data.Proofing.ProofingData['summary'],
    partials: Editor.Data.Proofing.ProofingData['partials'],
  ) => void;

  // selection
  SELECTION_UPDATED: (
    ranges: Editor.Selection.RangeData[],
    source: Realtime.Core.RealtimeSourceType,
  ) => void;
  UNDO_STATUS_CHANGED: (status: Realtime.Core.UndoManager.StackStatus) => void;
  REDO_STATUS_CHANGED: (status: Realtime.Core.UndoManager.StackStatus) => void;
  [x: string]: (...args: any[]) => void;
}

type DataManagerEventName = keyof DataManagerEvents;

class DataManagerEmitter extends BaseTypedEmitter<DataManagerEvents> {}

export class EventsController {
  ee: DataManagerEmitter;

  constructor() {
    this.ee = new DataManagerEmitter();
  }

  start(): void {}

  stop(): void {}

  destroy(): void {
    this.ee.destroy();
  }

  on<E extends DataManagerEventName, H extends DataManagerEvents[E]>(event: E, handler: H) {
    return this.ee.on(event, handler);
  }

  off<E extends DataManagerEventName, H extends DataManagerEvents[E]>(event: E, handler: H) {
    return this.ee.off(event, handler);
  }

  emit<E extends DataManagerEventName, H extends DataManagerEvents[E]>(
    event: E,
    ...args: Parameters<H>
  ) {
    return this.ee.emit(event, ...args);
  }
}
