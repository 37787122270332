import { SelectionFixer } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { Logger } from '_common/services';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager/models';
import { InsertElementOperation } from '../../Operations';
import { ErrorElementNotEditable } from '../../Errors';

export class DefaultCommand extends Command {
  protected event: Event;

  constructor(context: Editor.Edition.Context, event: Event) {
    super(context);
    this.event = event;
  }

  protected getEventData() {
    let dataToInsert: string | null = null;

    if (this.event instanceof KeyboardEvent) {
      dataToInsert = this.event.key;
    } else if (this.event instanceof CompositionEvent) {
      dataToInsert = this.event.data;
    } else if (this.event instanceof InputEvent) {
      dataToInsert = this.event.data;
    }
    //@ts-expect-error
    else if (this.event.data) {
      // failsafe
      //@ts-expect-error
      dataToInsert = this.event.data;
    }

    return dataToInsert;
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    ctx.setModelAndData(baseModel, baseData);

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
      },
      this.context.DataManager,
    );

    let textElementData;

    const result = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, ['p', 'tblc']);

    if (result) {
      if (NodeUtils.isParagraphData(result.data)) {
        textElementData = result.data;
      } else if (
        NodeUtils.isTableCellData(result.data) &&
        result.data.childNodes?.length === 0 &&
        result.path
      ) {
        // is table cell and does not have a paragraph
        const pathToInsert: Editor.Selection.Path = [...result.path, 'childNodes', 0];
        const paragraphData = NodeDataBuilder.buildParagraph({
          parent_id: result.data.id,
        });
        if (paragraphData) {
          new InsertElementOperation(baseModel, pathToInsert, paragraphData).apply();

          pathToInsert.push('childNodes', 0);

          ctx.range.updateRangePositions({
            b: ctx.range.start.b,
            p: pathToInsert,
          });
          textElementData = paragraphData;
        }
      }
    }

    let dataToInsert = this.getEventData();

    if (
      NodeUtils.isParagraphData(textElementData) &&
      dataToInsert &&
      this.context.contentManipulator
    ) {
      const closestFormat = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, ['format']);

      // check if textData is empty and extract appliers
      if (NodeUtils.isEmptyData(textElementData) && !closestFormat && this.context.stylesManager) {
        const styles = this.context.stylesManager.getStylesAppliedToData(textElementData, 'INLINE');
        this.context.stylesManager.addStylesToApply(styles);
      }

      // insert text
      if (this.context.contentManipulator.insertContent(ctx, ctx.range.start.p, dataToInsert)) {
        return true;
      }
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  private async applyPendingStyles(ctx: Editor.Edition.ActionContext) {
    if (!this.context.DataManager) {
      return false;
    }

    let dataToInsert = this.getEventData();

    // apply styles
    if (
      dataToInsert != null &&
      this.context.stylesManager?.havePendingStyles() &&
      this.context.selection?.modifiers
    ) {
      const range = ctx.range.cloneRange();

      this.context.selection.modifiers.modify(
        range,
        'expand',
        'character',
        'backward',
        dataToInsert.length,
      );

      // this.applySelection(range, false);

      await this.context.stylesManager.applyPendingStyles(range, { collapseSelection: 'END' });
      return true;
    }
    return false;
  }

  async handleExec() {
    if (this.context.debug) {
      Logger.trace('DefaultCommand exec', this);
    }

    if (!this.actionContext) {
      this.buildActionContext();
    }

    this.getSuggestionRefFromContent();

    if (!this.actionContext) {
      throw new Error('Context is not defined');
    }

    // handle non collapsed selection
    if (!this.actionContext.range.collapsed) {
      if (!this.handleNonCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      if (!this.handleCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    await this.handleSuggestionsUpdate();

    if (!(await this.applyPendingStyles(this.actionContext))) {
      this.applySelection();

      this.createPatch();
    }
  }
}
