import { Breadcrumb, BreadcrumbItem } from 'dodoc-design-system';
import styles from './ActionBarBreadcrumb.module.scss';
import { useDispatch } from '_common/hooks';
import { clearSelection } from '_common/components/Table/TableSlice';

export type PathElement = { id: string; name?: string };

type ActionBarBreadcrumbProps = {
  path: PathElement[];
  onBreadcrumbElementClick?: (element: PathElement) => void;
  pageLoading?: boolean;
};

const ActionBarBreadcrumb = ({
  path,
  onBreadcrumbElementClick,
  pageLoading,
}: ActionBarBreadcrumbProps) => {
  const dispatch = useDispatch();
  if (!path || pageLoading) {
    return null;
  }

  const onPathElementClick = (element: PathElement) => {
    if (onBreadcrumbElementClick) {
      if (element.id !== path[path.length - 1].id) {
        onBreadcrumbElementClick(element);
      }
    }
    dispatch(clearSelection());
  };

  return (
    <div className={styles.breadCrumb}>
      <Breadcrumb key={`breadcrumb-${path.length}`} testId="breadcrumb">
        {path.map((item) => {
          return (
            <BreadcrumbItem
              key={item.id}
              onClick={() => {
                onPathElementClick(item);
              }}
              testId={`${item.id}-breadcrumb`}
            >
              {item.name}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default ActionBarBreadcrumb;
