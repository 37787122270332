import { Command } from '../Command';
import { PathUtils } from 'Editor/services/_Common/Selection';
import { ELEMENTS } from 'Editor/services/consts';
import {
  DeleteCellsOperation,
  DeleteRowsOperation,
  InsertRowOperation,
  DeleteColumnsOperation,
  SplitCellsOperation,
  MergeCellsOperation,
  InsertColumnOperation,
} from '../../Operations/TableOperations';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { SortRowsOperation } from '../../Operations/TableOperations/SortRowsOperation';
import { NodeUtils } from 'Editor/services/DataManager';
import { TableUtils } from '../../Utils/TableUtils';
import { DistributeOperation } from '../../Operations/TableOperations/DistributeOperation';
import { ErrorElementNotEditable } from '../../Errors';

export class TableOperationCommand extends Command {
  operation: Editor.Edition.TableOperationsType;
  before: boolean = false;
  shiftCells: Editor.Edition.ShiftCellsType = 'SHIFT_LEFT';
  splitColumns: number = 2;
  splitRows: number = 1;
  mergeCells: boolean = false;
  index?: number[];
  pageWidth?: number;
  tableId?: string;
  tableNode?: Editor.Elements.TableElement;
  selectedCells: Editor.Edition.CellInfo[] = [];
  sortType?: Editor.Edition.SortType = 'ASC';
  tableInfo: {
    data: Editor.Data.Node.Data;
    path: Realtime.Core.RealtimePath;
  } | null = null;
  distributeType?: Editor.Edition.DistributeType;

  constructor(
    context: Editor.Edition.Context,
    operationArgs: Editor.Edition.TableOperationArgsType,
  ) {
    super(context);
    this.operation = operationArgs.operation;
    if (operationArgs.splitColumns && operationArgs.splitRows) {
      this.splitColumns = operationArgs.splitColumns;
      this.splitRows = operationArgs.splitRows;
    }
    this.operation = operationArgs.operation;

    if (operationArgs.before !== undefined) {
      this.before = operationArgs.before;
    }

    if (operationArgs.shiftCells !== undefined) {
      this.shiftCells = operationArgs.shiftCells;
    }

    if (operationArgs.index !== undefined) {
      this.index = operationArgs.index;
    }

    if (operationArgs.sortType !== undefined) {
      this.sortType = operationArgs.sortType;
    }

    if (operationArgs.distributeType !== undefined) {
      this.distributeType = operationArgs.distributeType;
    }
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    const range = this.actionContext.range.serializeToDOMRange();

    if (range) {
      const tableNode = EditorDOMUtils.closest(
        range.commonAncestorContainer,
        ELEMENTS.TableElement.TAG,
      );
      if (EditorDOMElements.isTableElement(tableNode)) {
        this.tableNode = tableNode;
        this.selectedCells = tableNode.getSelectedCellsInfo();
        this.tableId = tableNode.id;
        this.pageWidth = this.context.DataManager?.sections.getPageWidthForBlockId(tableNode.id);
        this.tableInfo = this.actionContext.baseModel.getChildInfoById(this.tableId);

        if (this.selectedCells.length === 0) {
          const cell = EditorDOMUtils.closest(
            range.commonAncestorContainer,
            ELEMENTS.TableCellElement.TAG,
          );

          if (EditorDOMElements.isTableCellElement(cell)) {
            this.selectedCells.push(cell.getCellInfo());
          }
        }

        this.applyOperation(this.actionContext);

        await this.handleSuggestionsUpdate();

        this.applySelection();

        this.createPatch();
      }
    }
  }

  private applyOperation(ctx: Editor.Edition.ActionContext) {
    switch (this.operation) {
      case 'DELETE_CELLS':
        return this.callDeleteCellsOperation(ctx);
      case 'DELETE_ROWS':
        return this.callDeleteRowsOperation(ctx);
      case 'DELETE_ROWS_INDEX':
        return this.callDeleteRowsIndexOperation(ctx);
      case 'DELETE_COLUMNS':
        return this.callDeleteColumnsOperation(ctx);
      case 'DELETE_COLUMNS_INDEX':
        return this.callDeleteColumnsIndexOperation(ctx);
      case 'INSERT_ROW':
        return this.callInsertRowOperation(ctx);
      case 'INSERT_ROW_INDEX':
        return this.callInsertRowIndexOperation(ctx);
      case 'INSERT_COLUMN':
        return this.callInsertColumnOperation(ctx);
      case 'INSERT_COLUMN_INDEX':
        return this.callInsertColumnIndexOperation(ctx);
      case 'MERGE_CELLS':
        return this.callMergeCellsOperation(ctx);
      case 'SPLIT_CELLS':
        return this.callSplitCellsOperation(ctx);
      case 'SORT_ROWS':
        return this.callSortRowsOperation(ctx);
      case 'DISTRIBUTE':
        return this.callDistributeOperation(ctx);
    }
  }

  private callDistributeOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId && this.distributeType && this.tableNode) {
      let rowsHeigth = this.tableNode.getComputedRowHeights();
      let columnWidth = this.tableNode.getComputedColumnWidths();

      rowsHeigth = rowsHeigth.map((h) => EditorDOMUtils.convertUnitTo(h, 'px', 'pt', 3));
      columnWidth = columnWidth.map((w) => EditorDOMUtils.convertUnitTo(w, 'px', 'pt', 3));

      new DistributeOperation(
        ctx.baseModel,
        this.tableId,
        this.selectedCells,
        this.distributeType,
        rowsHeigth,
        columnWidth,
      ).apply();

      return true;
    }
    return false;
  }

  private callSortRowsOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId && this.sortType) {
      const operation = new SortRowsOperation(
        ctx.baseModel,
        this.tableId,
        this.selectedCells,
        this.sortType,
      );

      operation.apply();

      const resultPath = operation.getAdjustedPath();

      if (resultPath) {
        ctx.range.updateRangePositions({
          b: ctx.range.start.b,
          p: resultPath,
        });
      }

      return true;
    }
    return false;
  }

  private callDeleteCellsOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId && this.tableInfo) {
      const tableCells = NodeUtils.querySelectorInData(this.tableInfo.data, ['tblc']);

      if (tableCells.length === this.selectedCells.length) {
        // REMOVE TABLE
        this.removeTable(ctx, this.tableId, this.tableInfo);

        return true;
      } else {
        new DeleteCellsOperation(
          ctx.baseModel,
          this.tableId,
          this.selectedCells,
          this.shiftCells,
          this.pageWidth,
        ).apply();

        return true;
      }
    }
    return false;
  }

  private callDeleteRowsOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId && this.tableInfo) {
      const indexRows = TableUtils.getRowsIndex(this.selectedCells);
      const tbody = this.tableInfo.data.childNodes?.[0];

      if (
        tbody &&
        tbody.childNodes &&
        tbody.childNodes.length > 0 &&
        tbody.childNodes.length === indexRows.length
      ) {
        // REMOVE TABLE
        this.removeTable(ctx, this.tableId, this.tableInfo);

        return true;
      } else {
        new DeleteRowsOperation(
          ctx.baseModel,
          this.tableId,
          this.shiftCells,
          this.selectedCells,
        ).apply();

        return true;
      }
    }
    return false;
  }

  private callDeleteRowsIndexOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId && this.index != null && this.tableInfo) {
      const tbody = this.tableInfo.data.childNodes?.[0];

      if (
        tbody &&
        tbody.childNodes &&
        tbody.childNodes.length > 0 &&
        tbody.childNodes.length === this.index.length
      ) {
        // REMOVE TABLE
        this.removeTable(ctx, this.tableId, this.tableInfo);

        return true;
      } else {
        new DeleteRowsOperation(
          ctx.baseModel,
          this.tableId,
          this.shiftCells,
          undefined,
          this.index,
        ).apply();

        return true;
      }
    }

    return false;
  }

  private callDeleteColumnsOperation(ctx: Editor.Edition.ActionContext) {
    if (
      ctx.baseModel &&
      this.tableId &&
      ctx.range &&
      this.tableInfo &&
      NodeUtils.isTableData(this.tableInfo.data)
    ) {
      const cellWidths = TableUtils.getColumnWidths(this.tableInfo.data);
      const cellsIndex = TableUtils.getCellsIndex(this.selectedCells);

      if (cellWidths.length === cellsIndex.length) {
        // REMOVE TABLE
        this.removeTable(ctx, this.tableId, this.tableInfo);

        return true;
      } else {
        new DeleteColumnsOperation(
          ctx.baseModel,
          this.tableId,
          this.pageWidth,
          this.selectedCells,
        ).apply();

        return true;
      }
    }
    return false;
  }

  private callDeleteColumnsIndexOperation(ctx: Editor.Edition.ActionContext) {
    if (
      ctx.baseModel &&
      this.tableId &&
      this.index != null &&
      this.tableInfo &&
      NodeUtils.isTableData(this.tableInfo.data)
    ) {
      const cellWidths = TableUtils.getColumnWidths(this.tableInfo.data);
      const cellsIndex = TableUtils.getCellsIndex(this.selectedCells);

      if (cellWidths.length === cellsIndex.length) {
        // REMOVE TABLE
        this.removeTable(ctx, this.tableId, this.tableInfo);

        return true;
      } else {
        new DeleteColumnsOperation(
          ctx.baseModel,
          this.tableId,
          this.pageWidth,
          undefined,
          this.index,
        ).apply();

        return true;
      }
    }
    return false;
  }

  private callInsertRowOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId) {
      new InsertRowOperation(ctx.baseModel, this.tableId, this.selectedCells, [], {
        before: this.before,
      }).apply();

      return true;
    }

    return false;
  }

  private callInsertRowIndexOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId && this.tableNode) {
      const idx = this.index?.[0];
      let rowsIndex: number[] = [];

      if (idx != null) {
        if (idx === 0 && this.tableNode.tBodies[0].rows[idx]) {
          rowsIndex.push(idx);
          this.before = true;
        } else if (this.tableNode.tBodies[0].rows[idx - 1]) {
          rowsIndex.push(idx - 1);
          this.before = false;
        }

        new InsertRowOperation(ctx.baseModel, this.tableId, this.selectedCells, rowsIndex, {
          before: this.before,
        }).apply();

        return true;
      }
    }
    return false;
  }

  private callInsertColumnOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId) {
      new InsertColumnOperation(
        ctx.baseModel,
        this.tableId,
        this.selectedCells,
        this.pageWidth,
        [],
        {
          before: this.before,
        },
      ).apply();

      return true;
    }

    return false;
  }

  private callInsertColumnIndexOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId) {
      const idx = this.index?.[0];
      let cellsIndex: number[] = [];

      if (idx != null) {
        if (idx === 0) {
          cellsIndex.push(idx);
          this.before = true;
        } else {
          cellsIndex.push(idx - 1);
          this.before = false;
        }

        new InsertColumnOperation(
          ctx.baseModel,
          this.tableId,
          this.selectedCells,
          this.pageWidth,
          cellsIndex,
          {
            before: this.before,
          },
        ).apply();

        return true;
      }
    }
    return false;
  }

  private callMergeCellsOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId) {
      new MergeCellsOperation(ctx.baseModel, this.tableId, this.selectedCells).apply();
      return true;
    }
    return false;
  }

  private callSplitCellsOperation(ctx: Editor.Edition.ActionContext) {
    if (ctx.baseModel && this.tableId) {
      new SplitCellsOperation(
        ctx,
        ctx.baseModel,
        this.tableId,
        this.selectedCells,
        this.splitColumns,
        this.splitRows,
        this.mergeCells,
        this.pageWidth,
      ).apply();

      return true;
    }
    return false;
  }

  private removeTable(
    ctx: Editor.Edition.ActionContext,
    tableId: string,
    tableInfo: {
      data: Editor.Data.Node.Data;
      path: Realtime.Core.RealtimePath;
    },
  ) {
    this.context.VisualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(tableId);

    if (PathUtils.isValidSelectionPath(tableInfo.path)) {
      // select table
      ctx.range.updateRangePositions(
        {
          b: ctx.range.start.b,
          p: [...tableInfo.path, 'childNodes', 0],
        },
        {
          b: ctx.range.start.b,
          p: [...tableInfo.path, 'childNodes', tableInfo.data.childNodes?.length || 0],
        },
      );

      this.context.contentManipulator?.removeContent(ctx);
    }
  }
}
