import { Selection } from '../../models';
import BaseController from '../BaseController';
import { SelectionHistory } from './SelectionHistory';

const BUILD_PRESENCE_ID = (documentId: string) => `SEL_${documentId}`;
const BUILD_USER_PRESENCE_KEY = (documentId: string, userId: string) =>
  `SEL_${documentId}_${userId}`;

export class SelectionController extends BaseController {
  private selectionData?: Selection;
  private selectionHistory: SelectionHistory;

  public modifiersData: Editor.Data.Selection.Modifiers = {};

  private debug: boolean = false;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.selectionHistory = new SelectionHistory();
    this.handleSelectionUpdate = this.handleSelectionUpdate.bind(this);
  }

  get history() {
    return this.selectionHistory;
  }

  get current() {
    return this.selectionHistory.last;
  }

  start(documentId: string): void {
    if (this.debug) {
      logger.info('SelectionController start');
    }
    this.selectionData = this.getSelectionModel(documentId);

    this.selectionData?.on('UPDATED', this.handleSelectionUpdate);
    this.loadSelectionData();
    this.submitUserSelection([]);
  }

  stop(): void {
    if (this.debug) {
      logger.info('SelectionController stop');
    }

    this.selectionData?.off('UPDATED', this.handleSelectionUpdate);
  }

  destroy(): void {
    if (this.debug) {
      logger.info('SelectionController destroy');
    }

    this.stop();
  }

  private submitUserSelection(
    ranges: Editor.Selection.RangeData[],
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    if (this.debug) {
      logger.trace('SelectionController submitUserSelection', ranges, options);
    }
    const userId = this.Data.users?.loggedUserId;
    if (!userId || !this.Data.context?.document?.id) {
      return;
    }
    this.selectionHistory.push(ranges);
    this.selectionData?.submit(
      BUILD_USER_PRESENCE_KEY(this.Data.context.document.id, userId),
      {
        id: userId,
        ranges,
      },
      options,
    );
  }

  loadSelectionData() {
    if (this.debug) {
      logger.debug('selection data', this.selectionData?.data);
    }
  }

  handleSelectionUpdate(
    source: Realtime.Core.RealtimeSourceType,
    presenceId: string,
    data: Editor.Data.Selection.Data | null,
  ) {
    if (data === null) {
      // The remote client is no longer present in the document
    } else {
      // Handle the new value by updating UI, etc.
      this.Data.events?.emit('SELECTION_UPDATED', data.ranges, source);
    }
  }

  updateModifiers(modifiersData: Editor.Data.Selection.Modifiers) {
    // update modifiers
    if (modifiersData.direction !== undefined) {
      this.modifiersData.direction = modifiersData.direction;
    }

    if (
      modifiersData.expandingDirection !== undefined &&
      modifiersData.expandingDirection !== this.modifiersData.expandingDirection
    ) {
      this.modifiersData.expandingDirection = modifiersData.expandingDirection;
    }

    if (
      modifiersData.cellSelection !== undefined &&
      modifiersData.cellSelection !== this.modifiersData.cellSelection
    ) {
      this.modifiersData.cellSelection = modifiersData.cellSelection;
    }

    if (modifiersData.px !== undefined && modifiersData.px !== this.modifiersData.px) {
      this.modifiersData.px = modifiersData.px;
    }

    if (modifiersData.py !== undefined && modifiersData.py !== this.modifiersData.py) {
      this.modifiersData.py = modifiersData.py;
    }
  }

  update(ranges: Editor.Selection.RangeData[]) {
    this.submitUserSelection(ranges);
  }

  setUserSelection(
    rangeData: Editor.Selection.RangeData | Editor.Selection.RangeData[],
    source: Realtime.Core.RealtimeSourceType = 'LOCAL_RENDER',
  ) {
    if (this.debug) {
      logger.trace('SelectionController setUserSelection', rangeData, source);
    }

    let ranges: Editor.Selection.RangeData[] = [];
    if (Array.isArray(rangeData)) {
      ranges = rangeData;
    } else {
      ranges = [rangeData];
    }

    this.submitUserSelection(ranges, {
      source: source,
    });
  }

  getSelectionModel(id: string) {
    return this.Data.models?.get(this.Data.models?.TYPE_NAME.SELECTION, BUILD_PRESENCE_ID(id));
  }

  restore(index?: number) {
    let ranges: Editor.Selection.RangeData[] = [];
    if (index === undefined) {
      ranges = this.selectionHistory.last;
    } else {
      ranges = this.selectionHistory.get(index);
    }
    if (ranges !== undefined && ranges.length) {
      this.setUserSelection(ranges, 'RESTORE');
    }
  }
}
