import ReduxInterface from 'Editor/services/ReduxInterface';
import * as Util from './utils/Util';
import { Logger } from '_common/services';
import {
  ApprovalsManager,
  NotesManager,
  PermissionsManager,
  StylesManager,
  WidgetManager,
} from './Managers';
import { NumberingCSSApplier } from './utils/NumberingCSSApplier';
import { Hook, Tabulator } from './utils';
import { ViewFactory } from './Views';
import { BlockViewModel, DocumentViewModel, ViewModelFactory } from './ViewModels';
import { BaseViewModel } from './ViewModels/BaseViewModel';
import { FontFamilyHelper } from '_common/utils/FontFamilyHelper';

export function VisualizerManager(editorContext: Editor.Context): Editor.Visualizer.API {
  const DEBUG = true;

  const Visualizer: Editor.Visualizer.State = {
    status: 'UNINITIALIZED',
    renderMode: 'BASIC',
    layoutType: 'WEB',
    hooks: { afterBlockNumberingUpdate: new Hook<'afterBlockNumberingUpdate'>() },
  };

  function debugMessage(message: string, ...args: any[]) {
    if (DEBUG) {
      Logger.debug(`[VisualizerManager] ${message}`, ...args);
    }
  }

  async function start(
    rootContainer: HTMLElement,
    elementToFocus?: { blockId: string; childId: string },
  ) {
    if (Visualizer.status === 'INITIALIZED' || Visualizer.status === 'INITIALIZING') {
      // Logger.captureException(new Error('VisualizerManager double initialization attempt!!'));
      return;
    }

    debugMessage('start', elementToFocus);

    Visualizer.status = 'INITIALIZING';

    Visualizer.selectionManager = editorContext.selectionManager;
    Visualizer.editionManager = editorContext.editionManager;
    Visualizer.stylesHandler = editorContext.stylesHandler;

    if (editorContext.DataManager) {
      try {
        Visualizer.dataManager = editorContext.DataManager;
        const docId = editorContext.DataManager.document.getDocumentId() as string;

        Visualizer.viewFactory = new ViewFactory(editorContext.DataManager, Visualizer);
        Visualizer.viewModelFactory = new ViewModelFactory(editorContext.DataManager, Visualizer);
        Visualizer.rootContainer = rootContainer;
        Visualizer.contentContainer = rootContainer;
        Visualizer.fontFamilyHelper = new FontFamilyHelper(
          ReduxInterface.getPlatformInfo(),
          'editor',
        );
        Visualizer.styles = new StylesManager(editorContext.DataManager, Visualizer);
        Visualizer.notes = new NotesManager(editorContext.DataManager, Visualizer);
        Visualizer.numberingCSSApplier = new NumberingCSSApplier(editorContext.DataManager);
        Visualizer.approvals = new ApprovalsManager(editorContext.DataManager, Visualizer);
        Visualizer.permissions = new PermissionsManager(editorContext.DataManager, Visualizer);
        Visualizer.widgets = new WidgetManager(editorContext.DataManager, Visualizer);

        await Visualizer.fontFamilyHelper.start();
        await Visualizer.styles.start().pending();
        Visualizer.notes.start();
        Visualizer.approvals.start();
        Visualizer.permissions.start();
        Visualizer.widgets.start();
        Visualizer.tabulator = new Tabulator(
          editorContext.DataManager,
          Visualizer,
          editorContext.selection,
        );

        ReduxInterface.setLayout(Visualizer.layoutType);

        Visualizer.selectionViewModel = Visualizer.viewModelFactory?.getSelection(docId);
        Visualizer.selectionViewModel?.bindView(Visualizer.rootContainer);

        Visualizer.dVM = new DocumentViewModel(editorContext.DataManager, Visualizer, docId);
        await Visualizer.dVM.bindView(Visualizer.contentContainer);

        if (elementToFocus) {
          await Visualizer.dVM.renderAt(elementToFocus.blockId, elementToFocus.childId);

          setTimeout(() => {
            // apply selection
            Visualizer.selectionViewModel?.setSelectionTo(
              elementToFocus.blockId,
              elementToFocus.childId,
              'end',
            );
          }, 0);
        } else {
          await Visualizer.dVM.render();

          setTimeout(() => {
            // apply selection
            const child = Visualizer.dVM?.children.getAtIndex(0);
            if (child) {
              Visualizer.selectionViewModel?.setSelectionTo(child.id, undefined, 'start');
            }
          }, 0);
        }

        Visualizer.hooks.afterBlockNumberingUpdate?.register(handleBlockNumberingUpdate);

        // TODO: emit document rendered

        Visualizer.status = 'INITIALIZED';
        debugMessage('INITIALIZED');
      } catch (error) {
        // Logger.captureException(error);
        throw error;
      }
    } else {
      throw new Error('DataManager not initialized');
    }
  }

  function handleBlockNumberingUpdate(blockIds: string[]) {
    for (let i = 0; i < blockIds.length; i++) {
      Visualizer.tabulateView?.(blockIds[i]);
    }
  }

  function stop() {
    Visualizer.status = 'DESTROYING';
    Visualizer.approvals?.destroy();
    Visualizer.permissions?.destroy();
    Visualizer.notes?.destroy();
    Visualizer.dVM?.dispose();
    Visualizer.viewModelFactory?.destroy();
    Visualizer.hooks.afterBlockNumberingUpdate?.unregister(handleBlockNumberingUpdate);

    Visualizer.fontFamilyHelper?.destroy();

    Visualizer.styles?.destroy();

    Visualizer.widgets?.destroy();
    Visualizer.status = 'DESTROYED';
    debugMessage('DESTROYED');
  }

  function destroy() {
    debugMessage('destroy');
    stop();
  }

  function getViewFactory(): ViewFactory | undefined {
    return Visualizer.viewFactory;
  }

  function getRenderMode(): Editor.Visualizer.RenderMode {
    return Visualizer.renderMode;
  }

  function getViewModelById(id: string): BaseViewModel | undefined {
    return Visualizer.dVM?.getChildById(id);
  }

  async function renderAs(renderMode: Editor.Visualizer.RenderMode) {
    if (renderMode !== 'BASIC') {
      Visualizer.layoutType = 'WEB';
    }
    Visualizer.renderMode = renderMode;
    await Visualizer.dVM?.changeLayout();
    ReduxInterface.setLayout(Visualizer.layoutType);
  }

  async function changeLayout(layout: Editor.Visualizer.LayoutType) {
    if (layout !== 'WEB') {
      Visualizer.renderMode = 'BASIC';
    }
    Visualizer.layoutType = layout;
    await Visualizer.dVM?.changeLayout();
    ReduxInterface.setLayout(Visualizer.layoutType);
  }

  function getLayoutType(): Editor.Visualizer.LayoutType {
    return Visualizer.layoutType;
  }

  async function centerAtBlock(blockId: string, childId?: string) {
    return Visualizer.dVM?.centerWindowAt(blockId, childId);
  }

  function isBlockRendered(blockId?: string, childId?: string) {
    return Visualizer.dVM?.isBlockRendered(blockId, childId);
  }

  function reRenderBlock(blockId: string) {
    return Visualizer.dVM?.reRenderBlock(blockId);
  }

  function isReadOnly() {
    return ReduxInterface.isReadonly();
  }

  function scrollIntoView(
    nodeId: string,
    alignOption: string = 'CLOSEST',
    checkViewport?: boolean,
  ) {
    return Visualizer.dVM?.scrollIntoView(nodeId, alignOption, checkViewport);
  }

  function stopSelectionTracker() {
    Visualizer.selectionViewModel?.stopSelectionTracker();
  }

  function startSelectionTracker() {
    Visualizer.selectionViewModel?.startSelectionTracker();
  }

  function debounceStartSelectionTracker() {
    Visualizer.selectionViewModel?.debounceStartSelectionTracker();
  }

  /**
   * @deprecated will not work if the selection is the same as before
   */
  function triggerSelectionChanged(updataModifiers?: boolean) {
    Visualizer.selectionViewModel?.triggerSelectionChanged(updataModifiers);
  }

  /**
   * @deprecated will not work if the selection is the same as before
   */
  function debounceSelectionChanged(updataModifiers?: boolean) {
    Visualizer.selectionViewModel?.debounceSelectionChanged(updataModifiers);
  }

  function checkSelectionStatus() {
    Visualizer.stylesHandler?.scheduleCheckSelectionStatus();
  }

  function getWidgetsManager() {
    return Visualizer.widgets;
  }

  function getFontFamilyHelper() {
    return Visualizer.fontFamilyHelper;
  }

  function getTabStops(view: Editor.Visualizer.BaseView) {
    return Visualizer.tabulator?.getTabStops(view) || [];
  }

  function tabulateView(id: string) {
    const viewModel = Visualizer.dVM?.getChildById(id);
    if (viewModel instanceof BlockViewModel) {
      Visualizer.tabulator?.tabulate(viewModel);
    }
  }

  function getStatus() {
    return Visualizer.status;
  }

  const API: Editor.Visualizer.API = {
    getStatus,
    isReady: () => Visualizer.status === 'INITIALIZED',
    start,
    stop,
    destroy,
    getRenderMode,
    renderAs,
    Visualizer,
    getViewFactory,
    changeLayout,
    getLayoutType,
    isBlockRendered,
    centerAtBlock,
    reRenderBlock,
    scrollIntoView,
    isReadOnly,
    getViewModelById,
    getWidgetsManager,
    getFontFamilyHelper,
    tabulateView,
    selection: {
      stopSelectionTracker,
      startSelectionTracker,
      debounceStartSelectionTracker,
      triggerSelectionChanged,
      debounceSelectionChanged,
      checkSelectionStatus,
    },
    getTabStops,
  };

  Util.extend(Visualizer, {
    ...API,
  });

  return API;
}
